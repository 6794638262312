import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Dimmer, Divider, Dropdown, Form, Grid, Header, Icon, Input, List, Loader, Modal, Popup, Segment, Select, TextArea } from 'semantic-ui-react';
import { ServicesType } from '../../types/ServicesTypes';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import { AuthState, Department, User } from '../../types';
import { fetchAllActiveUsers } from '../../services/Users';
import { addServiceInstance, deleteServiceInstance, fetchServiceInstance, fetchServiceInstanceImage, updateServiceInstance, uploadServiceInstanceImage, upsertSignage } from '../../services/ServiceInstances';
import { toast } from 'react-toastify';
import { fetchAllActiveServicesTypes, fetchServiceTypesImage, getServiceCategories, uploadServiceImage } from '../../services/service';
import { useSelector } from 'react-redux';
import ServiceAudioRecorder from '../AudioRecorder/serviceAudioRecorder';
import { checkDestPlayableWithTimeDelay, getUploadUrl, uploadToSignedUrl } from '../../services/PplOfTheMonth';
import { v4 as uuidv4 } from 'uuid';
import { ServiceAddOn } from '../../types/Service';
import { Asset } from '../../types/Asset';
import { listAllRestaurant, listAssets } from '../../services/Assets';
import { listRoomTypes } from '../../services/RoomTypes';
import { serviceSourceType, LocationOption } from '../../types/ServiceInstance';
import { fetchDepartments } from '../../services/Departments';
import { getDurationInMs, getFormattedEndDateString } from '../../util/timezone';
import { sortByKey } from '../../util/sortData';
import { isFacilityAdmin } from '../../services/Permissions';
import { getFormattedExDatesArrayFromRecurrence } from '../../util/recurrence';
import ExcludeDatesField from '../ExcludeDatesField';
import { mealTypes, sendToast } from '../../util';
import SVCEventImageUploader from '../SvcImageUpdloader';

interface InstanceProps {
    instanceData: Partial<ServicesType>;
    setInstanceData: React.Dispatch<React.SetStateAction<Partial<ServicesType>>>;
    isEdit?: boolean;
    setIsEdit?: () => void;
    closeModal: () => void;
    reloadServices: () => void;
    source?: serviceSourceType;
    setCalendarTypesOptions?: React.Dispatch<React.SetStateAction<LocationOption[]>>;
    handleCalendarReload?: any;
    getThisAndFollowingDate?: (date: string | undefined) => void;
    isRegistrationLocked?: boolean;
    isToggleChangedByUser?: boolean;
    
}

function ServiceInstanceFormCreate({
    instanceData,
    setInstanceData,
    isEdit = false,
    setIsEdit,
    closeModal,
    reloadServices,
    source,
    setCalendarTypesOptions,
    handleCalendarReload,
    getThisAndFollowingDate,
    isRegistrationLocked,
    isToggleChangedByUser
}: InstanceProps) {
    const profile = useSelector(({ authReducer }: { authReducer: AuthState; }) => authReducer.profile);
    const [loading, setLoading] = useState<boolean>(false);
    const [staffList, setStaffList] = useState<User[]>([]);
    const [imageLink, setImageLink] = useState<string>('');
    const [selectedImage, setSelectedImage] = useState<File | null>(null);
    const [services, setServices] = useState<ServicesType[]>([]);
    const [serviceLoader, setServiceLoader] = useState<boolean>(false);
    const [imageLoading, setImageLoading] = useState<boolean>(false);
    const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
    const [audio, setAudio] = useState<Blob | undefined>(undefined);
    const [copy, setCopy] = useState<boolean>(false);
    const [addon, setAddOn] = useState<Partial<ServiceAddOn>>({});
    const [defaultAssets, setDefaultAssets] = useState<Asset[]>([]);
    const [recEndDate, setRecEndDate] = useState<string | undefined>(undefined);
    const [recModal, setRecModal] = useState<boolean>(false);
    const [repeatType, setRepeatType] = useState<string>('OFF');
    const [interval, setInterval] = useState<number>(1);
    const [locationOptions, setLocationOptions] = useState<[]>([]);
    const [modifyGroup, setModifyGroup] = useState<boolean>(false);
    const [departments, setDepartments] = useState<Department[]>([]);
    const [duration, setDuration] = useState<number | undefined>(undefined);
    const [categoryOptions, setCategoryOptions] = useState<string[]>([]);
    const [excludeDates, setExcludeDates] = useState<string[]>([]);
    const [datePickerMinDate, setDatePickerMinDate] = useState<Date | null>(null);
    const [datePickerMaxDate, setDatePickerMaxDate] = useState<Date | null>(null);
    const [thisAndFollowingOptionSelected, setThisAndFollowingOptionSelected] = useState(false)
    const [selectedThisAndFollowingDate, setSelectedThisAndFollowingDate] = useState<string | undefined>(undefined)
    const dateTimeFormat = 'YYYY-MM-DDTHH:mm:ss';
    const hasMounted = useRef(false);

    useEffect(() => {
        fetchCategories();
        fetchStaffList();
        fetchServices();
        fetchAssetsAndDepartments();
    }, []);

    useEffect(() => {
        if (instanceData.startDate && duration !== undefined) {
            const updatedEndDate = getFormattedEndDateString(instanceData.startDate, duration, dateTimeFormat);
            handleChangeInstanceData('endDate', updatedEndDate);
        }
    }, [instanceData.startDate, duration]);

    useEffect(() => {
        if (instanceData.endDate && instanceData.startDate) { // here there is no check for start date as it will be always there when end date is there
            const updatedDuration = getDurationInMs(instanceData.startDate, instanceData.endDate);
            setDuration(updatedDuration);
        }
    }, [instanceData.endDate]);

    useEffect(() => {
        try {
            const fetchImage = async () => {
                setImageLoading(true);
                if (instanceData.image && instanceData.Facility && instanceData._id && !instanceData.srcId) {
                    const link = await fetchServiceInstanceImage(instanceData._id, instanceData.image, instanceData.Facility);
                    setImageLink(link);
                } else if (instanceData.image && instanceData.Facility && instanceData.srcId) {
                    const link = await fetchServiceInstanceImage(instanceData.srcId, instanceData.image, instanceData.Facility);
                    setImageLink(link);
                }
            };
            fetchImage();
        } catch (error) {
            toast.error(error instanceof Error ? error.message : "Failed to fetch image", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            console.error(error);
        } finally {
            setImageLoading(false);
        }
    }, [instanceData.image]);

    useEffect(() => {   //if recurrance is there in the data tobe edited , Modal has to be opened
        if (isEdit && instanceData.recurrence) {
            setRecModal(true);
        }
    }, [isEdit, instanceData._id]);

    useEffect(() => { // Destrucutre the recurrence and set the values in the modal
        if (modifyGroup || thisAndFollowingOptionSelected) {
            if (instanceData.recurrence && isEdit) {

                const interval: number = instanceData.recurrence.split('INTERVAL=')[1] && !isNaN(
                    Number(instanceData.recurrence.split('INTERVAL=')[1].split(';')[0])
                ) && Number(instanceData.recurrence.split('INTERVAL=')[1].split(';')[0]) || 1;

                const Freq: string = instanceData.recurrence.split('FREQ=')[1] && instanceData.recurrence.split('FREQ=')[1].split(';')[0] || 'OFF';
                const endDate = moment(instanceData.recurrence.split('UNTIL=')[1] && instanceData.recurrence.split('UNTIL=')[1].split('\n')[0].replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3')) // Get end date from recurrence string.
                    .set({
                        hour: Number(moment(instanceData.startDate).format('HH')),
                        minute: Number(moment(instanceData.startDate).format('mm')),
                        second: Number(moment(instanceData.startDate).format('ss')),
                    }).format(dateTimeFormat);
                const match = instanceData.recurrence.match(/DTSTART=(\d+)/);

                const exDatesStringFromRecurrence = instanceData.recurrence.split('EXDATE:')[1];
                const exDatesArrayFromRecurrence = exDatesStringFromRecurrence ? exDatesStringFromRecurrence.split(',') : [];
                const formattedDatesArray = getFormattedExDatesArrayFromRecurrence(exDatesArrayFromRecurrence);

                const dtStartValue = match && match[1];
                const formattedDTStart = moment(dtStartValue).set({
                    hour: Number(moment(instanceData.startDate).format('HH')),
                    minute: Number(moment(instanceData.startDate).format('mm')),
                    second: Number(moment(instanceData.startDate).format('ss')),
                }).format(dateTimeFormat);
                const formattedEndDate = moment(dtStartValue).set({
                    hour: Number(moment(instanceData.endDate).format('HH')),
                    minute: Number(moment(instanceData.endDate).format('mm')),
                    second: Number(moment(instanceData.endDate).format('ss')),
                }).format(dateTimeFormat);
                handleChangeInstanceData(undefined, undefined, { "startDate": formattedDTStart, "endDate": formattedEndDate });
                setInterval(interval);
                setRecEndDate(endDate);
                setRepeatType(Freq);
                setExcludeDates(formattedDatesArray);
            }
        }
    }, [modifyGroup, thisAndFollowingOptionSelected]);

    useEffect(() => {
        if (instanceData.startDate && recEndDate) {
            const datePickerMinDate = moment(instanceData.startDate).add(1, 'day').toDate();
            setDatePickerMinDate(datePickerMinDate);
            const datePickerMaxDate = moment(recEndDate).subtract(1, 'day').toDate();
            setDatePickerMaxDate(datePickerMaxDate);
            // check if any items in excludeDates are outside the range of datePickerMinDate and datePickerMaxDate and remove them
            const updatedExDates = excludeDates.filter((date) => {
                return moment(date).isBetween(datePickerMinDate, datePickerMaxDate, 'day', '[]');
            });
            setExcludeDates(updatedExDates);
        }
    }, [instanceData.startDate, recEndDate]);

    useEffect(() => {
        if (!hasMounted.current) {
            hasMounted.current = true; // Mark as mounted
            return;
        }
        handleFormSubmit();
    }, [isToggleChangedByUser]); 
    
    const fetchCategories = async () => {
        try {
            const categories = await getServiceCategories({ sortAlphabetically: true });
            setCategoryOptions(categories);
        } catch (error) {
            toast.warn(error instanceof Error ? error.message : "Failed to get categories", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    };

    const filteredCategories = categoryOptions && categoryOptions.length > 0 && categoryOptions.filter((category) => {
        if (source === "DailyActivities") {
            if (category !== 'Menu') return category;
        } else if (source === "Menu") {
            if (category === 'Menu') return category;
        } else {
            return category;
        }
    });

    const categoriesDropdownOptions = filteredCategories && filteredCategories.length > 0 && filteredCategories.map((category) => {
        return {
            key: category,
            text: category,
            value: category
        };
    }
    ) || [];

    const serviceOptions = services.map((service) => {
        return {
            key: service._id, text: service.name, value: service._id
        };
    });

    const assetOptions = defaultAssets.map((asset) => {
        return {
            key: asset._id, text: asset.AssetName, value: asset._id
        };
    });

    let departmentOptions: any = [];
    if (source === "DailyActivities") {
        departmentOptions = departments
            .filter((department) => department.Name !== "Kitchen" && department.Name !== "Dining")
            .map((department) => ({
                key: department._id,
                text: department.Name,
                value: department._id,
            }));
    } else if (source === "Menu") {
        departmentOptions = departments
            .filter((department) => department.Name === "Kitchen" || department.Name === "Dining")
            .map((department) => ({
                key: department._id,
                text: department.Name,
                value: department._id,
            }));
    } else {
        // Handle other cases or provide a default value
        departmentOptions = departments
            .map((department) => ({
                key: department._id,
                text: department.Name,
                value: department._id,
            }));;
    }

    const handleAssetSelection = (value) => {
        const asset = defaultAssets.find((asset) => asset._id === value);
        setInstanceData({
            ...instanceData,
            Asset: value,
            defaults: {
                ...instanceData.defaults,
                internalCostRequirement: asset && String(asset.MinCap) || "",
                externalCostRequirement: asset && String(asset.MaxCap) || "",
            }
        });
    };

    const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const selectedFile = event.target.files[0];
            if (selectedFile) {
                const allowedFileExtensions = ['jpg', 'png'];
                const fileNameParts = selectedFile.name.split('.');
                const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();
                // Check if the selected file type is either PNG or JPG
                const allowedFileTypes = ['image/png', 'image/jpg', 'image/jpeg'];
                if (allowedFileExtensions.includes(fileExtension) && allowedFileTypes.includes(selectedFile.type)) {
                    setSelectedImage(selectedFile);
                } else {
                    toast.warn("Only PNG and JPG files are allowed", {
                        position: 'bottom-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                }
            }
        }
    };

    const fetchAssetsAndDepartments = async () => {
        try {
            const assets = source === "Menu" ? await listAllRestaurant() : await listAssets();
            const departmentsArr = await fetchDepartments();
            if (source === "Menu" && departmentsArr && departmentsArr.length > 0) {
                const diningDepartment = departmentsArr.find((department) => department.Name === "Dining");
                if (!diningDepartment) {
                    sendToast('error', 'Dining Department not found');
                } else {
                    if (isEdit) { // while editing, we are passing setState function as setInstanceData, so we need to use prevState to update the state
                        setInstanceData((prevState) => {
                            return {
                                ...prevState,
                                category: "Menu",
                                Department: String(diningDepartment._id)
                            };
                        });
                    } else { // while creating, we are passing a custom function to update the state, so we need to use the function to update the state
                        setInstanceData({
                            ...instanceData,
                            category: "Menu",
                            Department: String(diningDepartment._id)
                        });
                    }
                }
            }
            setDefaultAssets(assets);
            setDepartments(departmentsArr);
        } catch (error) {
            console.error("error fetching assets");
            toast.error(error instanceof Error ? error.message : "Failed to fetch  Assets", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    };

    const fetchServices = async () => {
        setServiceLoader(true);
        try {
            if (profile && profile.Facility) {
                let category;
                if (source === "DailyActivities") {
                    category = { $ne: "Menu" };
                } else if (source === "Menu") {
                    category = "Menu";
                }
                const Filter = { Facility: profile.Facility, active: true, category: category };
                const allservices = await fetchAllActiveServicesTypes({ Filter });
                const roomTypes = await listRoomTypes();
                const locationOption = roomTypes.Result && roomTypes.Result.map(el => ({ key: el._id, value: el._id, text: el.Name }));
                setLocationOptions(locationOption);
                if (setCalendarTypesOptions) {
                    setCalendarTypesOptions(locationOption);
                }
                setServices(allservices);
            }
        } catch (error) {
            toast.warn(error instanceof Error ? error.message : "Failed to fetch data", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } finally {
            setServiceLoader(false);
        }
    };

    const convertSignedLink = async (fileName: string, link = imageLink): Promise<boolean> => {
        try {
            setImageLoading(true);
            setLoading(true);
            const response = await fetch(link);
            const blob = await response.blob();
            const supportedImageTypes = ['image/png', 'image/jpg', 'image/jpeg'];
            if (!supportedImageTypes.includes(blob.type)) {
                toast.warn(
                    "Only PNG and JPG files are allowed, Service image not added to service instance",
                    {
                        position: 'bottom-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
                return false;
            }
            const file = new File([blob], fileName, { type: blob.type });
            setSelectedImage(file);
            return true;
        } catch (err) {
            toast.error(err instanceof Error ? err.message : "Failed to copy image", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
            console.log(err);
            return false;
        } finally {
            setLoading(false);
            setImageLoading(false);
        }
    };

    const handleServiceSelect = async (value) => {
        try {
            setLoading(true);
            const service: Partial<ServicesType> | undefined = services.find(service => service._id == value);
            if (service) {
                let isImageValid = false;
                if (service.image && service.Facility && service._id) {
                    const facility = profile && profile.Facility || service.Facility;
                    const link = await fetchServiceTypesImage(service._id, service.image, facility); // service.image
                    isImageValid = await convertSignedLink(service.image, link);
                }
                const updatedInstanceData = { ...service, startDate: instanceData.startDate, endDate: instanceData.endDate, calendarType: instanceData.calendarType };
                if (!isImageValid) {
                    delete updatedInstanceData.image;
                }
                setInstanceData({ ...updatedInstanceData });
            }
        } catch (error) {
            toast.error(error instanceof Error ? error.message : "Failed to fetch selected service", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } finally {
            setLoading(false);
        }
    };

    const handleChangeInstanceData = (name, data, updateObj?) => {
        if (updateObj && Object.keys(updateObj).length > 0) {
            Object.keys(updateObj).forEach(key => {
                setInstanceData(prevState => ({
                    ...prevState,
                    [key]: updateObj[key]
                }));
            });
            return;
        }
        if (["tags", "expert", "autoAnnouncement", "internalCost", "externalCost", "internalCostRequirement",
            "externalCostRequirement", "displayInSignage", "minutesBeforeAnnouncement", "alertTarget", "autoNotification", "shareToCalendars", "waitingList", "automateWaitingList"].includes(name)) {
            if (name === "autoAnnouncement" || name === "autoNotification") {
                setInstanceData({
                    ...instanceData,
                    defaults: {
                        ...instanceData.defaults,
                        [name]: data,
                        alertTarget: "registered",
                        minutesBeforeAnnouncement: instanceData.defaults && instanceData.defaults.minutesBeforeAnnouncement ? instanceData.defaults.minutesBeforeAnnouncement : 30
                    }
                });
                return;
            }
            if (name === "alertTarget") {
                if (data) {
                    data = "all";
                } else {
                    data = "registered";
                }
            }

            if (name === "waitingList") {// We handle the waiting list in a separate if block because whenever the waiting list status changes from false to true, we need to automatically set the automate waiting list checkbox to true.
                const previousValue = instanceData.defaults && instanceData.defaults.waitingList;
                const waitingListChangeFromFalseToTrue = previousValue === false && data === true;
                setInstanceData({
                    ...instanceData,
                    defaults: {
                        ...instanceData.defaults,
                        waitingList: data,
                        automateWaitingList: waitingListChangeFromFalseToTrue ? data : instanceData.defaults && instanceData.defaults.automateWaitingList
                    }
                });
                return;
            }
            setInstanceData({
                ...instanceData,
                defaults: {
                    ...instanceData.defaults,
                    [name]: data
                }
            });
            return;
        }
        setInstanceData({
            ...instanceData,
            [name]: data
        });
    };

    const checkValidation = () => {
        return (
            instanceData &&
            instanceData.name && instanceData.name.length > 0 &&
            instanceData.shortDescription && instanceData.shortDescription.length > 0 &&
            instanceData.category && instanceData.category.length > 0 &&
            instanceData.startDate && instanceData.endDate &&
            instanceData.calendarType && instanceData.calendarType.length > 0 &&
            instanceData.Asset && instanceData.Asset.length > 0 &&
            instanceData.Department &&
            (
                (repeatType !== 'OFF' && instanceData.startDate && recEndDate) ||
                (repeatType === 'OFF')
            ) && (
                (source === "Menu" && instanceData.menuCategory) || (source === "DailyActivities")
            )
        );
    };


    const handleFormSubmit = async (createAService: boolean = false) => {
        try {
            setLoading(true);
            if (source === "Menu") {
                const diningDepartment = departments.find((department) => department.Name === "Dining");
                if (!diningDepartment) {
                    sendToast('error', 'Dining Department not found for the facility');
                    return;
                }
                instanceData.category = "Menu";
                instanceData.Department = String(diningDepartment._id);
            }
            let data;
            if (source === "DailyActivities") {
                data = { ...instanceData, isRegistrationLocked: isRegistrationLocked };
            } else {
                data = { ...instanceData };
            }
            let {
                internalCostRequirement = 0,
                externalCostRequirement = 0
            } = data.defaults || {};
            const hasInvalidInternalCost = Number(internalCostRequirement) === 0 || Number(internalCostRequirement) < 0 || internalCostRequirement === "";
            const hasInvalidExternalCost = Number(externalCostRequirement) === 0 || Number(externalCostRequirement) < 0 || externalCostRequirement === "";
            if (hasInvalidInternalCost || hasInvalidExternalCost) {
                toast.warn("Min capacity, Max capacity should not be less than or equal to zero.", {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                return;
            } else if (Number(internalCostRequirement) > Number(externalCostRequirement)) {
                toast.warn("Min capacity should not be greater than Max capacity.", {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                return;
            }
            if (!isEdit && instanceData._id) { //
                instanceData.originalServiceId = instanceData._id;
                delete data._id;
            }
            if (audio) {
                const destAudioId = await handleAudio();
                if (destAudioId) {
                    data.audioId = destAudioId;
                }
            }
            if (modifyGroup) {
                data["modifyGroup"] = modifyGroup;
            }

            if (thisAndFollowingOptionSelected) {
                data["thisAndFollowingDate"] = selectedThisAndFollowingDate
                const newRecurrence = generateReccurenceStringFromInputs(selectedThisAndFollowingDate, recEndDate, repeatType, excludeDates.map(formatDate).join(','), interval);
                data["newRecurrence"] = newRecurrence;
                // figure out the first use-case from the following
                const oldInstanceSrcItem = await fetchServiceInstance({ Filter: { _id: instanceData.srcId } });
                const oldInstanceSrcItmeStartDate = oldInstanceSrcItem.startDate;
                const selectedThisAndFollowingStartDate = moment.tz(selectedThisAndFollowingDate, profile && profile.FacilityTimeZone).format(dateTimeFormat);
                console.log({ oldInstanceSrcItmeStartDate, selectedThisAndFollowingStartDate });
                const isFirstItemInRecurrenceBeingUpdated = moment(oldInstanceSrcItmeStartDate).isSame(moment(selectedThisAndFollowingStartDate), 'day');
                console.log("isFirstItemInRecurrenceBeingUpdated", isFirstItemInRecurrenceBeingUpdated);
                if (isFirstItemInRecurrenceBeingUpdated) { // if first item is being updated then newRecurrence and old recurrence will be same
                    console.log("first item is being updated");
                    data["recurrence"] = newRecurrence;
                } else {
                    console.log("is it here");
                    const oldInstanceReccurenceDataStartDate = oldInstanceSrcItem.startDate;
                    const oldInstanceReccurenceDataEndDay = moment.tz(selectedThisAndFollowingDate, profile && profile.FacilityTimeZone).subtract("1", "day").format("YYYY-MM-DD");
                    // add time to oldInstanceReccurenceDataEndDate using the end time from the oldInstanceSrcItem
                    const oldInstanceReccurenceDataEndDate = moment.tz(oldInstanceReccurenceDataEndDay, profile && profile.FacilityTimeZone).set({
                        hour: Number(moment(oldInstanceSrcItem.startDate).format('HH')),
                        minute: Number(moment(oldInstanceSrcItem.startDate).format('mm')),
                        second: Number(moment(oldInstanceSrcItem.startDate).format('ss')),
                    }).format(dateTimeFormat);
                    const srcItemRepeatType = oldInstanceSrcItem.recurrence.split('FREQ=')[1] && oldInstanceSrcItem.recurrence.split('FREQ=')[1].split(';')[0] || 'OFF';
                    const oldReccurence = generateReccurenceStringFromInputs(oldInstanceReccurenceDataStartDate, oldInstanceReccurenceDataEndDate, srcItemRepeatType, excludeDates.map(formatDate).join(','), interval);
                    data["recurrence"] = oldReccurence;
                }

            }

            console.log("data is this", data)
            if (instanceData.startDate && recEndDate && repeatType && interval && !thisAndFollowingOptionSelected) {
                data.recurrence = generateRecurrence();
            }
            if (selectedImage) data.image = selectedImage.name;
            const response = isEdit ? await updateServiceInstance(data) : await addServiceInstance(data, createAService);
            console.log("response", response);
            let serviceId: string = "";
            let newRecurrenceServiceInstanceId: string = ""; // if we are updating this and following instance we sent the new recurrence id
            if (response.oldRecurrenceSrcId || response.newRecurrenceSrcId) { 
                if (response.oldRecurrenceSrcId) {
                    serviceId = response.oldRecurrenceSrcId;
                }
                if (response.newRecurrenceSrcId) { // this is the new recurrence id for this and following instance
                    console.log("this case can be")
                    newRecurrenceServiceInstanceId = response.newRecurrenceSrcId;
                }
                if (!response.oldRecurrenceSrcId && response.newRecurrenceSrcId) {
                    console.log("only this  case")
                    serviceId = response.newRecurrenceSrcId;
                }
            } else {
                serviceId = response._id;
            }
            console.log("serviceId", serviceId);
            console.log("newRecurrenceServiceInstanceId", newRecurrenceServiceInstanceId);
            let signedUrl = '';
            let newRecurrenceSignedUrl = '';
            if (selectedImage) { //if image is changed , upload the image to s3 
                if(!newRecurrenceServiceInstanceId) {
                    signedUrl = await uploadServiceInstanceImage(selectedImage, serviceId, profile && profile.Facility || "");
                } else {
                    newRecurrenceSignedUrl = await uploadServiceInstanceImage(selectedImage, newRecurrenceServiceInstanceId, profile && profile.Facility || "");
                }
                if (createAService) {
                    signedUrl = await uploadServiceImage(selectedImage, response.originalServiceId, profile && profile.Facility || "", instanceData.universal || undefined);
                }
            }
            if (instanceData.defaults && instanceData.defaults.displayInSignage) {
                // Set endDate to be less than 9 PM
                // YYYY-MM-DDTHH:mm:ss
                const endOfDay = moment(instanceData.endDate).hour(21).minute(0).seconds(0).format(dateTimeFormat); // Set to 9 PM in UT
                if (instanceData.startDate && moment(instanceData.startDate).isSameOrAfter(endOfDay)) {
                    toast.warn(`Signage won't be created for selected time range`, {
                        position: 'bottom-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    if (isEdit) {
                        // check if this instance has an image, then use the image to re-create signage
                        const imageKey = instanceData.image || '';
                        if (imageKey && !signedUrl) {
                            console.log("recreating signage with old image", imageKey, signedUrl);
                            await upsertSignage(serviceId, signedUrl, isEdit, imageKey);
                            if (newRecurrenceServiceInstanceId) {// only create signage if we are updating this and following instance
                                // if we are updating this and following instance with new image use the selected image as imageKey
                                const newRecurrenceImageKey = selectedImage && selectedImage.name || instanceData.image || '';
                                console.log("is this case", newRecurrenceImageKey, newRecurrenceSignedUrl, isEdit, newRecurrenceServiceInstanceId);
                                await upsertSignage(newRecurrenceServiceInstanceId, newRecurrenceSignedUrl, isEdit, newRecurrenceImageKey);
                            }
                        } else {
                            console.log("this case", signedUrl, newRecurrenceSignedUrl, isEdit);
                            // else re-create with default image
                            await upsertSignage(serviceId, signedUrl, isEdit);
                            if (response.oldRecurrenceSrcId && newRecurrenceServiceInstanceId) { // only create signage if we are updating this and following instance
                                console.log("this case2", newRecurrenceSignedUrl, newRecurrenceServiceInstanceId, isEdit);
                                await upsertSignage(newRecurrenceServiceInstanceId, newRecurrenceSignedUrl, isEdit);
                            }
                        }
                    } else {
                        // on creating a new service instance use the object url from upload to create a signage
                        console.log(
                            "creating signage with new image",
                            signedUrl,
                            serviceId
                        )
                        await upsertSignage(serviceId, signedUrl);
                    }
                    if (instanceData.defaults.displayInSignage) {
                        toast.success("Signage creation in progress. Please visit the signage page in a few minutes to confirm that the correct image is reflected.", {
                            position: 'bottom-center',
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                }
            }
            if (response && response.warningMessage) {
                const warnings = Object.keys(response.warningMessage);
                warnings.forEach((warning) => {
                    if (warning) {
                        toast.warn(`${response.warningMessage[warning]}`, {
                            position: 'top-right',
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                });
            }

            toast.success(isEdit ? "Service instance updated successfully" : "Service instance created successfully", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });

            setAudio(undefined);
            closeModal();
            handleCalendarReload && handleCalendarReload();
            if (createAService) reloadServices();
        } catch (error) {
            console.error("Error in API", error);
            toast.error(`${error}`, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        } finally {
            setLoading(false);
        }
    };

    const fetchStaffList = async () => {
        try {
            const users = await fetchAllActiveUsers();
            setStaffList(users);
        } catch (error) {
            toast.error(error instanceof Error ? error.message : "Failed to fetch staff list", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const handleAddItem = () => {
        const addItem = addon;
        if (addItem.itemName && addItem.itemCost && addItem.itemName.trim() !== '') {
            addItem.id = uuidv4();
            if (instanceData.ServiceAddOns) { handleChangeInstanceData("ServiceAddOns", [...instanceData.ServiceAddOns, { ...addItem, ["required"]: addItem.required || false }]); }
            else { handleChangeInstanceData("ServiceAddOns", [{ ...addItem }]); }
            setAddOn({});
        } else {
            toast.warn(`Fill all the items in Add-ons to add `, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const handleDeleteAddonItem = (item: ServiceAddOn) => {
        if (instanceData.ServiceAddOns) {
            const updatedItems = instanceData.ServiceAddOns.filter((addon) => addon.id !== item.id);
            handleChangeInstanceData("ServiceAddOns", updatedItems);
        }
    };

    const handleServieAddonChange = (index: number, field: string, value: any) => {
        if (instanceData.ServiceAddOns) {
            const updatedItems = [...instanceData.ServiceAddOns];
            if (field == "required") {
                updatedItems[index].required = !updatedItems[index].required;
            } else if (field == "itemCost") {
                updatedItems[index].itemCost = Number(value); 
            } else if (field == "itemName") {
                updatedItems[index].itemName = value;
            }
            handleChangeInstanceData("ServiceAddOns", updatedItems);
        }
    };

    const handleCopyButton = async () => {
        try {
            const data = { ...instanceData };
            let link = '';
            const imageId = data.srcId ? data.srcId : data._id;
            if (imageId && data.image && data.Facility) {
                link = await fetchServiceInstanceImage(imageId, data.image, data.Facility);
            }
            ['_id', 'recurrence', 'srcId', 'LastUpdated', '_version', 'UpdatedBy'].forEach((field) => {
                delete data[field];
            });
            setInstanceData(data);
            if (data.image && link) {
                await convertSignedLink(data.image, link);
            }
            if (setIsEdit) {
                setIsEdit();
            }
            setCopy(true);
        } catch (error) {
            toast.error(error instanceof Error ? error.message : "Failed to copy details", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
            console.error(error);
        }
    };

    const handleDelete = async () => {
        try {
            setLoading(true);
            const _id = instanceData._id;
            if (_id) {
                const response = await deleteServiceInstance({ _id, deleteGroup: modifyGroup, srcId: instanceData.srcId && instanceData.srcId || undefined, thisAndFollowingDate: selectedThisAndFollowingDate });
                if (!response) {
                    throw new Error('Instance could not be deleted');
                }
            } else {
                throw new Error('Instance Id not found');
            }
            closeModal();
        } catch (error) {
            console.error(error);
            toast.error(`${error}`, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        } finally {
            setLoading(false);
        }
    };

    const audioBlobHandler = (audio: Blob | undefined) => {
        setAudio(audio);
    };

    const uploadAudiofile = async () => {
        const { audioId = undefined, signedUrl = undefined } = await getUploadUrl('service_instance');
        if (audio || instanceData.audio || instanceData.audioId) {
            const file = new File([audio || instanceData.audio || instanceData.audioId || ""], audioId, {
                type: 'audio/wav',
            });
            await uploadToSignedUrl(file, signedUrl);
            return audioId;
        }
    };

    const handleAudio = async () => {
        try {
            const audioId = await uploadAudiofile();
            if (audioId) {
                const destAudioId = audioId.replace(/\.wav$/, '.mp3'); // replaces the extension serviceInstance
                setInstanceData({ ...instanceData, audioId: destAudioId });
                let destPlayableExists: string | undefined;
                for (let i = 0; i < 6; i++) {
                    destPlayableExists = await checkDestPlayableWithTimeDelay(
                        destAudioId,
                        'service_instance',
                        Math.pow(2, i) * 1000,
                    );
                    if (destPlayableExists) {
                        break;
                    }
                }
                if (destPlayableExists) { // if dest exists return dest or else throw error
                    return destAudioId;
                } else {
                    throw new Error("'Failed to process audio. Please start over'");
                }
            } else {
                throw new Error("Error uploading audio. Please start over");
            }
        } catch (error) {
            console.error(error, 'error handle audio');
            toast.error(`${error}`, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        }
        return false;
    };

    const intervalType = () => {
        const correctGrammar = (value) => (interval > 1 ? value + 's' : value);
        switch (repeatType) {
            case 'DAILY':
                return correctGrammar('Day');
            case 'WEEKLY':
                return correctGrammar('Week');
            case 'MONTHLY':
                return correctGrammar('Month');
            default:
                return '';
        }
    };
    const formatDate = (dateString: string) => {
        const [year, month, day] = dateString.split('-');
        return `${year}${month}${day}`;
    };

    const generateRecurrence = () => {
        let recurrence;
        if(instanceData && instanceData.startDate && recEndDate && repeatType) {
            const dtstart = instanceData.startDate.split('T')[0].split('-').join('');
            const until = recEndDate.split('T')[0].split('-').join('');
            const excludeDatesString = excludeDates.length ? excludeDates.map(formatDate).join(',') : '';
            
        
            if (dtstart && until && repeatType) {
                if (repeatType === '1FR') {
                    recurrence = `DTSTART=${dtstart}\nRRULE:FREQ=MONTHLY;BYDAY=1FR;UNTIL=${until}`;
                } else {
                    recurrence = `DTSTART=${dtstart}\nRRULE:FREQ=${repeatType};INTERVAL=${interval};UNTIL=${until}`;
                }
                if (excludeDatesString) {
                    recurrence += `\nEXDATE:${excludeDatesString}`;
                }
            }
            
            return recurrence;
        }
    };
    

    const generateReccurenceStringFromInputs = (userSelectedStartDate, userSelectedEndDate, frequency, datesToBeExcluded, interval) => {
        
        // Function to format date from ISO string to YYYYMMDD
        const formatDate = (dateString: string) => {
            return moment(dateString).format('YYYYMMDD');
        };        
    
        const formattedStartDate = formatDate(userSelectedStartDate);
        const formattedEndDate = formatDate(userSelectedEndDate);
    
        let reccurenceString = userSelectedStartDate && userSelectedEndDate && frequency ? 
            `DTSTART=${formattedStartDate}\nRRULE:FREQ=${frequency};INTERVAL=${interval};UNTIL=${formattedEndDate}` : 
            "";
        
        if (datesToBeExcluded) {
            reccurenceString += `\nEXDATE:${datesToBeExcluded}`;
        }
        
        return reccurenceString;
    }

    const handleDateChange = (date: Date | null) => {
        const dateString = date && moment(date).format('YYYY-MM-DD');
        if (date && !excludeDates.some(excludeDate => excludeDate === dateString)) {
            setExcludeDates([...excludeDates, dateString]);
        }
    };

    const removeExDate = (dateToRemove: string) => {
        const updatedExDates = excludeDates.filter(date => date !== dateToRemove);
        setExcludeDates(updatedExDates);
    };

    const mealCategoryOptions = mealTypes.filter(item => item !== 'Any')
        .map(item => {
            return {
                key: item.toLowerCase(),
                value: item.toLowerCase(),
                text: item,
            };
        });

    return (
        <>
            <div className='create-instance-form'>
                <Dimmer inverted active={loading}>
                    <Loader active={loading}>Processing</Loader>
                </Dimmer>
                <Form >
                    {
                        isEdit ? <Header as='h4' className='create-instance-form-header' content='Update event' textAlign='center' />
                            : <Header as='h4' className='create-instance-form-header' content='Create new event' textAlign='center' />
                    }
                    {source !== "Menu" && !isEdit ? <Form.Field >
                        <label htmlFor="service">Existing service</label>
                        <div className='dropdown-container'>
                            <Dropdown
                                placeholder={services.length === 0 ? "No services available." : "Choose from an existing service"}
                                fluid
                                disabled={services.length === 0 ? true : false}
                                search
                                selection
                                loading={serviceLoader}
                                options={sortByKey(serviceOptions)}
                                onChange={(e, { value = "" }) => {
                                    handleServiceSelect(value);
                                }}
                            />
                        </div>
                    </Form.Field> : ""}
                    <Grid>
                        <Grid.Row columns={2} textAlign='center'>
                            <Grid.Column width={8}>
                                <div className="audio-recorder self-centre">
                                    <Popup
                                        trigger={<Form.Field>
                                            <ServiceAudioRecorder passAudioBlob={audioBlobHandler} />
                                            {instanceData.audio && isEdit && <div style={{ marginTop: "10px" }}>
                                                <p className='self-centre'>Current audio</p>
                                                <audio controls src={instanceData.audio}><code>your current browser does not support audio</code></audio>
                                            </div>}
                                            {instanceData.audio && copy && <div>
                                                <p className='self-centre'>Current audio</p>
                                                <audio controls src={instanceData.audio}><code>your current browser does not support audio</code></audio>
                                            </div>}
                                        </Form.Field>}
                                        content="Add audio"
                                    />
                                </div>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <Dimmer inverted active={imageLoading}>
                                    <Loader active={imageLoading}></Loader>
                                </Dimmer>
                                <div style={{ textAlign: 'center' }}>
                                    <input
                                        id="imageInput"
                                        type="file"
                                        accept=".jpg, .png"
                                        style={{ display: 'none' }}
                                        onChange={handleImageChange}
                                    />
                                    {instanceData.image && !selectedImage ?
                                        <label htmlFor="imageInput" style={{ cursor: "pointer" }}>
                                            <img
                                                src={imageLink}
                                                style={{ maxWidth: '100px', maxHeight: '100px' }}
                                                onLoad={() => setImageLoading(false)}
                                            />
                                        </label>
                                        :
                                        <div style={{ cursor: "pointer" }}>
                                            {!selectedImage && <label htmlFor="imageInput" style={{ cursor: "pointer" }}>
                                                <Icon name="images" size="huge" />
                                            </label>}

                                            {selectedImage && (
                                                <label htmlFor="imageInput" style={{ cursor: "pointer" }}>
                                                    <img src={URL.createObjectURL(selectedImage)} style={{ maxWidth: '100px', maxHeight: '100px' }} onLoad={() => setImageLoading(false)} />
                                                </label>
                                            )}
                                        </div>
                                    }
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Form.Field required={true}>
                        <label htmlFor="serviceName">
                            {source === "Menu" ? "Menu Item" : "Service Name"}
                            <span style={{ color: "red", fontSize: "0.8em", opacity: "0.7" }}>  (25 Chars)</span>
                        </label>
                        <div style={{ display: "flex" }}>
                            <Input
                                placeholder={source === "Menu" ? "Menu Item" : "Service Name"}
                                value={instanceData.name || ""}
                                onChange={(e) => handleChangeInstanceData("name", e.target.value)}
                                maxLength={25}
                            />
                            {
                                source !== "Menu" && isEdit ?
                                    <Popup
                                        trigger={<Icon onClick={handleCopyButton}
                                            style={{ position: "relative", top: "4px", left: "5px", cursor: "pointer" }}
                                            name='file outline'
                                            size='big' />}
                                        content="Copy event"
                                    />
                                    : <></>
                            }
                        </div>
                        {instanceData.name && instanceData.name.length >= 25 && (
                            <p style={{ color: 'red', marginTop: '5px', textAlign: 'center' }}>
                                Maximum length reached (25 characters).
                            </p>)}
                    </Form.Field>
                    <Form.Field required={true}>
                        <label htmlFor="shortDescription">
                            Short Description
                        </label>
                        <TextArea
                            placeholder="Short Description"
                            value={instanceData.shortDescription || ""}
                            onChange={(e) => handleChangeInstanceData("shortDescription", (e.target as HTMLTextAreaElement).value)}
                        />
                    </Form.Field>
                    {source && source === "Menu" && <Form.Field required={true}>
                        <label htmlFor="menuCategory">{source === "Menu" ? "Meal Types" : "Menu Category"}</label>
                        <Dropdown
                            fluid
                            selection
                            placeholder={source === "Menu" ? "Meal Types" : "Menu Category"}
                            options={mealCategoryOptions}
                            value={instanceData.menuCategory}
                            onChange={(event, { value }) => {
                                handleChangeInstanceData("menuCategory", value);
                            }}
                            className="menu-dropdown"
                        />
                    </Form.Field>}
                    {source !== "Menu" && <Form.Field required={true}>
                        <label htmlFor="serviceCategory">Service Category</label>
                        <Dropdown
                            placeholder="Service Category"
                            selection
                            options={sortByKey(categoriesDropdownOptions)}
                            value={instanceData.category}
                            search
                            onChange={(e, { value }) => handleChangeInstanceData("category", value)}
                        />
                    </Form.Field>}
                    <div className='self-centre'>
                        <Form.Field style={{ width: "100%" }} required={true}>
                            <label htmlFor="serviceCategory">Start time</label>
                            <DatePicker //startDate
                                customInput={
                                    <div style={{ position: 'relative' }}>
                                        <Icon
                                            name="calendar alternate outline"
                                            style={{
                                                position: 'absolute',
                                                top: '45%',
                                                left: '10px',
                                                transform: 'translateY(-50%)',
                                            }}
                                        />
                                        <input
                                            style={{ border: '1px solid #183466', paddingLeft: '30px' }}
                                            placeholder="Start time"
                                            value={instanceData.startDate ? moment(instanceData.startDate).format('M.D.Y h:mm A') : ''}
                                        />
                                    </div>
                                }
                                dateFormat="M.d.Y h:mm aa"
                                showTimeSelect
                                timeIntervals={15}
                                selected={instanceData.startDate ? Date.parse(instanceData.startDate || new Date().toISOString()) : null}
                                onChange={(date: Date) => {
                                    if (!date) return;

                                    const startTime = moment(date).format(dateTimeFormat);
                                    let endTime = "";
                                    if (!instanceData.endDate) {
                                        endTime = moment(startTime).add(15, 'minutes').format(dateTimeFormat);
                                        setInstanceData({
                                            ...instanceData,
                                            endDate: endTime,
                                            startDate: startTime
                                        });
                                        setSelectedThisAndFollowingDate(startTime);
                                        if (getThisAndFollowingDate) {
                                            getThisAndFollowingDate(startTime);
                                        }                                        
                                    } else {
                                        handleChangeInstanceData(
                                            'startDate',
                                            startTime
                                        );
                                        const startDateTime = moment(startTime).format('HH:mm:ss');
                                        const thisAndFollowingEventsWithUpdatedStartTime = moment(selectedThisAndFollowingDate).set({
                                            hour: Number(moment(startDateTime, 'HH:mm:ss').format('HH')),
                                            minute: Number(moment(startDateTime, 'HH:mm:ss').format('mm')),
                                            second: Number(moment(startDateTime, 'HH:mm:ss').format('ss')),
                                        }).format(dateTimeFormat);
                                        console.log({ thisAndFollowingEventsWithUpdatedStartTime})
                                        setSelectedThisAndFollowingDate(thisAndFollowingEventsWithUpdatedStartTime);
                                        if (getThisAndFollowingDate) {
                                            getThisAndFollowingDate(thisAndFollowingEventsWithUpdatedStartTime);
                                        }
                                    }
                                }}
                            />
                        </Form.Field>
                        <Form.Field style={{ width: "100%" }} required={true}>
                            <label htmlFor="serviceCategory">End time</label>
                            <DatePicker //endDate
                                customInput={
                                    <div style={{ position: 'relative' }}>
                                        <Icon
                                            name="calendar alternate outline"
                                            style={{
                                                position: 'absolute',
                                                top: '45%',
                                                left: '10px',
                                                transform: 'translateY(-50%)',
                                            }}
                                        />
                                        <input
                                            style={{ border: '1px solid #183466', paddingLeft: '30px', }}
                                            placeholder="End time"
                                            value={instanceData.endDate ? moment(instanceData.endDate).format('M.D.Y h:mm A') : ''}
                                            onChange={() => { }}
                                        />
                                    </div>
                                }
                                dateFormat="M.d.Y h:mm aa"
                                showTimeSelect
                                timeIntervals={15}
                                disabled={!instanceData.startDate}
                                selected={instanceData.endDate ? new Date(instanceData.endDate) : null}
                                minDate={instanceData.startDate ? new Date(instanceData.startDate) : null}
                                minTime={instanceData.startDate ? new Date(moment(instanceData.startDate).add(15, 'minutes').valueOf()) : null}
                                maxTime={
                                    instanceData.startDate
                                        ? new Date(instanceData.startDate).setHours(23, 59, 59) // Set the maxTime to end of the day
                                        : null
                                }
                                maxDate={instanceData.startDate ? new Date(instanceData.startDate) : null}
                                onChange={(date: Date) => {
                                    const endDate = moment(date).format(dateTimeFormat);
                                    handleChangeInstanceData('endDate', endDate);
                                }}
                            />
                        </Form.Field>
                    </div>
                    <Form.Field required={true}>
                        <label htmlFor="Location/Asset">{source === "Menu" ? "Restaurant" : "Location/Asset"}</label>
                        <Dropdown
                            placeholder={source === "Menu" ? "Restaurant" : "Location/Asset"}
                            selection
                            options={sortByKey(assetOptions)}
                            value={instanceData.Asset}
                            onChange={(e, { value }) => handleAssetSelection(value)}
                            loading={serviceLoader}
                            search
                        />
                    </Form.Field>
                    {source !== "Menu" && <Form.Field >
                        <label htmlFor="tags">Tags</label>
                        <TextArea
                            placeholder="Tags"
                            value={instanceData.defaults && instanceData.defaults.tags}
                            onChange={(e) => handleChangeInstanceData('tags', (e.target as HTMLTextAreaElement).value)}
                        />
                    </Form.Field>}
                    {source !== "Menu" && <Form.Field required={true}>
                        <label htmlFor="department">Department</label>
                        <Dropdown
                            placeholder="Department"
                            selection
                            options={sortByKey(departmentOptions)}
                            value={instanceData.Department}
                            onChange={(e, { value }) => handleChangeInstanceData("Department", value)}
                            loading={serviceLoader}
                            search
                        />
                    </Form.Field>}
                    <Form.Field>
                        <Grid columns={2}>
                            <Grid.Column width={8}>
                                <Checkbox
                                    style={{ paddingTop: '10px', width: "100%" }}
                                    label="Waiting list"
                                    checked={instanceData.defaults && instanceData.defaults.waitingList}
                                    onChange={(e, { checked }) => handleChangeInstanceData('waitingList', checked)}
                                />
                                {
                                    isEdit && instanceData.defaults && instanceData.defaults.waitingList &&
                                    <Checkbox
                                        style={{ paddingTop: '10px', width: "100%" }}
                                        label="Automate Waiting List Queue"
                                        checked={instanceData.defaults && instanceData.defaults.automateWaitingList}
                                        onChange={(e, { checked }) => handleChangeInstanceData('automateWaitingList', checked)}
                                    />
                                }
                                <Checkbox
                                    style={{ paddingTop: '10px', width: "100%" }}
                                    label="Share to calendar"
                                    checked={instanceData && instanceData.defaults && instanceData.defaults.shareToCalendars}
                                    onChange={(e, { checked }) => handleChangeInstanceData('shareToCalendars', checked)}
                                />
                                <Checkbox
                                    style={{ paddingTop: '10px', width: "100%" }}
                                    label="Display in Signage"
                                    checked={instanceData.defaults && instanceData.defaults.displayInSignage}
                                    onChange={(e, { checked }) => handleChangeInstanceData('displayInSignage', checked)}
                                />
                                <Checkbox
                                    style={{ paddingTop: '10px', width: "100%" }}
                                    label="Notification"
                                    checked={instanceData.defaults && instanceData.defaults.autoNotification}
                                    onChange={(e, { checked }) => handleChangeInstanceData('autoNotification', checked)}
                                />
                                <Checkbox
                                    style={{ paddingTop: '10px', width: "100%" }}
                                    label="Announcement"
                                    checked={instanceData.defaults && instanceData.defaults.autoAnnouncement}
                                    onChange={(e, { checked }) => handleChangeInstanceData('autoAnnouncement', checked)}
                                />
                                {
                                    instanceData.defaults && (instanceData.defaults.autoAnnouncement || instanceData.defaults.autoNotification) &&
                                    <Segment style={{ display: 'flex-column', justifyItems: "right" }}>
                                        {instanceData.defaults && instanceData.defaults.autoAnnouncement &&
                                            <Form.Field>
                                                <Popup
                                                    content="minutes"
                                                    position="top center"
                                                    trigger={<Input
                                                        style={{ width: '100px' }}
                                                        name="minutesBeforeAnnouncement"
                                                        placeholder="Minutes"
                                                        type='number'
                                                        value={instanceData.defaults && instanceData.defaults.minutesBeforeAnnouncement}
                                                        onChange={(event, { name, value }) => {
                                                            const mins = Number(value);
                                                            if (mins < 0) {
                                                                toast.warn('Duration should be greater than 0',
                                                                    {
                                                                        position: 'bottom-center',
                                                                        autoClose: 5000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                    });
                                                            } else {
                                                                handleChangeInstanceData('minutesBeforeAnnouncement', mins);
                                                            }
                                                        }}
                                                    />}
                                                />

                                            </Form.Field>}
                                        <Form.Field style={{ display: 'flex', alignItems: 'center' }}>
                                            <label style={{ fontWeight: 'lighter', marginTop: '7px' }}>Registered</label>
                                            <Checkbox
                                                style={{ marginLeft: '5px' }}
                                                name="alertTarget"
                                                toggle
                                                checked={instanceData.defaults && instanceData.defaults.alertTarget === 'all' ? true : false} // Check if it's 'All'
                                                onChange={(e, { checked }) => handleChangeInstanceData('alertTarget', checked)}
                                            />
                                            <label style={{ fontWeight: 'lighter', marginLeft: '5px', marginTop: '7px' }}>All</label>
                                        </Form.Field>

                                    </Segment>
                                }
                                <Checkbox
                                    style={{ paddingTop: '10px', width: "100%" }}
                                    label="Registration"
                                    checked={instanceData && instanceData.showAttendance}
                                    onChange={(e, { checked }) => handleChangeInstanceData('showAttendance', checked)}
                                />
                            </Grid.Column>
                        </Grid>
                    </Form.Field>
                    {
                        isEdit ? <>
                            {source !== "Menu" && <Form.Field>
                                <label htmlFor="expert">Expert</label>
                                <Select
                                    name="expert"
                                    placeholder="Select expert"
                                    options={sortByKey(staffList.filter((staff) => {
                                        if (!isFacilityAdmin(staff)) {
                                            return staff;
                                        }
                                    }).map((staff) => {
                                        return {
                                            key: staff._id,
                                            text: `${staff.FirstName} ${staff.LastName}`,
                                            value: staff._id,
                                        };
                                    }))}
                                    onChange={(e, { value }) => handleChangeInstanceData("expert", value)}
                                    value={instanceData.defaults && instanceData.defaults.expert}
                                />
                            </Form.Field>}
                            {source !== "Menu" && <Form.Field>
                                <label htmlFor="url">URL</label>
                                <Input
                                    name="url"
                                    placeholder="URL"
                                    value={instanceData.url || ""}
                                    onChange={(e) => handleChangeInstanceData("url", e.target.value)}
                                />
                            </Form.Field>}
                            <div className='self-centre' >
                                <Form.Field style={{ width: "100%" }} required={true}>
                                    <label htmlFor="minCapacity">Min Capacity</label>
                                    <Input
                                        type='number'
                                        placeholder="Min Capacity"
                                        value={instanceData.defaults && instanceData.defaults.internalCostRequirement || ""}
                                        onChange={(e) => handleChangeInstanceData("internalCostRequirement", e.target.value)}
                                    />
                                </Form.Field>
                                <Form.Field style={{ width: "100%" }} required={true}>
                                    <label htmlFor="maxCapacity">Max Capacity</label>
                                    <Input
                                        type='number'
                                        placeholder="Max Capacity"
                                        value={instanceData.defaults && instanceData.defaults.externalCostRequirement || ""}
                                        onChange={(e) => handleChangeInstanceData("externalCostRequirement", e.target.value)}
                                    />
                                </Form.Field>
                            </div>
                            <div className='self-centre'>
                                <Form.Field style={{ width: "100%" }}>
                                    <label htmlFor="internalCost">{source === "Menu" ? "Our Cost" : "Internal Cost"}</label>
                                    <Input
                                        type='number'
                                        placeholder={source === "Menu" ? "Our Cost" : "Internal $"}
                                        value={instanceData.defaults && instanceData.defaults.internalCost || ""}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (Number(value) >= 0) {
                                                handleChangeInstanceData("internalCost", e.target.value);
                                            }
                                        }}
                                    />
                                </Form.Field>
                                <Form.Field style={{ width: "100%" }}>
                                    <label htmlFor="externalCost">{source === "Menu" ? "Price" : "External Cost"}</label>
                                    <Input
                                        type='number'
                                        placeholder={source === "Menu" ? "Price" : "External $"}
                                        value={instanceData.defaults && instanceData.defaults.externalCost || ""}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (Number(value) >= 0) {
                                                handleChangeInstanceData("externalCost", value);
                                            }
                                        }}
                                    />
                                </Form.Field>
                            </div>
                        </> : <></>
                    }
                    <div className='flex-centre' style={{ width: "100%", marginTop: "10px" }}>
                        <Form.Field style={{ width: '300px' }} required={true} >
                            <label htmlFor="maxCapacity">Calendar Type</label>
                            <Dropdown
                                selection
                                multiple
                                control={Select}
                                placeholder="Select Calendar"
                                options={sortByKey(locationOptions)}
                                onChange={(e, { value }) => {
                                    if (value) {
                                        handleChangeInstanceData('calendarType', value);

                                    }
                                }}
                                value={instanceData.calendarType}
                                loading={serviceLoader}
                            />
                        </Form.Field>
                        <Form.Field style={{ width: '300px' }}>
                            <Dropdown
                                placeholder="Repeats"
                                value={repeatType}
                                fluid
                                selection
                                options={[
                                    {
                                        key: 'Off',
                                        text: 'Repeat off',
                                        value: 'OFF',
                                    },
                                    {
                                        key: 'Daily',
                                        text: 'Repeat daily',
                                        value: 'DAILY',
                                    },
                                    {
                                        key: 'Weekly',
                                        text: 'Repeat weekly',
                                        value: 'WEEKLY',
                                    },
                                    {
                                        key: 'Monthly',
                                        text: 'Repeat monthly',
                                        value: 'MONTHLY',
                                    },
                                    {
                                        key:'Monthly on first friday',
                                        text:'Repeat monthly on first friday',
                                        value:'1FR'
                                    }
                                ]}
                                onChange={(e, { value }) => {

                                    setRepeatType(String(value));
                                    if (value === 'OFF') {
                                        setInstanceData({ ...instanceData, recurrence: undefined });
                                    }
                                }}
                            />
                        </Form.Field>
                        {repeatType && repeatType !== 'OFF' && (
                            <Form.Field inline >
                                <label>Once every</label>
                                <Input
                                    type="number"
                                    min={1}
                                    value={interval}
                                    onChange={(e, { value }) => {
                                        const newInterval = parseInt(value, 10);
                                        if (!isNaN(newInterval) && newInterval > 0) {
                                            setInterval(newInterval);
                                        }
                                    }}
                                />
                                <label style={{ marginLeft: '10px' }}>{intervalType()}</label>
                            </Form.Field>
                        )}
                        {instanceData.startDate && repeatType && repeatType !== 'OFF' && (
                            <Form.Field required={true} style={{ width: '300px' }}>
                                <label>Recurrence End Date</label>
                                <div className="customDatePickerWidth">
                                    <DatePicker
                                        style={{ border: '1px solid #183466' }}
                                        placeholderText="Select End Date for Recurrence"
                                        dateFormat="M.d.Y h:mm aa"
                                        selectsEnd
                                        selected={recEndDate && moment(recEndDate).toDate()}
                                        startDate={instanceData.startDate && moment(instanceData.startDate).toDate()}
                                        endDate={recEndDate && moment(recEndDate).toDate()}
                                        minDate={moment(instanceData.startDate).toDate()}
                                        maxDate={moment(instanceData.startDate).add(6, 'months').toDate()}
                                        onChange={(date) => {
                                            const localDate = moment(date).set({
                                                hour: parseInt(moment(instanceData.startDate).format('HH')),
                                                minute: parseInt(moment(instanceData.startDate).format('mm')),
                                                second: parseInt(moment(instanceData.startDate).format('ss')),
                                            }).format(dateTimeFormat);
                                            setRecEndDate(localDate);
                                        }}
                                    />
                                </div>
                            </Form.Field>
                        )}
                        {instanceData.startDate && recEndDate && repeatType && repeatType !== 'OFF' && (
                            <ExcludeDatesField
                                excludeDates={excludeDates}
                                setExcludeDates={(excludeDates) => setExcludeDates(excludeDates)}
                                datePickerMinDate={datePickerMinDate}
                                datePickerMaxDate={datePickerMaxDate}
                            />
                        )}
                    </div>
                    <div className='self-centre'>
                        <Form.Button color='blue' disabled={checkValidation() ? false : true} onClick={() => handleFormSubmit()}>{isEdit ? "Update" : "Save"}</Form.Button>
                        <Form.Button onClick={() => closeModal()} >Cancel</Form.Button>
                        {isEdit ? <Form.Button color='red' onClick={() => { setOpenConfirmModal(true); }} >Delete</Form.Button> : <></>}
                    </div>
                    {!isEdit && <div className='self-centre'>
                        <Form.Button color='blue' disabled={checkValidation() ? false : true} onClick={() => handleFormSubmit(true)}>Save as new Service</Form.Button>
                    </div>}
                    <Form.Field>
                        <label htmlFor='longDescription'>Long Description</label>
                        <Form.TextArea
                            placeholder="Long Description"
                            value={instanceData && instanceData.longDescription || ""}
                            onChange={(e) => handleChangeInstanceData("longDescription", (e.target as HTMLTextAreaElement).value)}
                        />
                    </Form.Field>
                    {Array.isArray(instanceData.ServiceAddOns) &&
                        <List>
                            {instanceData.ServiceAddOns &&
                                instanceData.ServiceAddOns.length > 0 &&
                                instanceData.ServiceAddOns.map(
                                    (item: ServiceAddOn, index) =>
                                        item && (
                                            <List.Item key={index}>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        gap: '10px',
                                                        alignItems: 'center',
                                                        padding: '0px 10px',
                                                    }}
                                                >
                                                    <Icon
                                                        name="trash alternate"
                                                        size="large"
                                                        onClick={() => handleDeleteAddonItem(item)}
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                    <Input
                                                        placeholder="Item"
                                                        value={item.itemName}
                                                        onChange={(e) => {
                                                            handleServieAddonChange(index, 'itemName', e.target.value);
                                                        }}
                                                    />
                                                    <Input
                                                        placeholder="Item Cost"
                                                        type="number"
                                                        value={item.itemCost || 0}
                                                        onChange={(e) => {
                                                            handleServieAddonChange(index, 'itemCost', e.target.value);
                                                        }}
                                                    />
                                                    <Checkbox
                                                        checked={item.required}
                                                        onChange={(e, { checked }) =>
                                                            handleServieAddonChange(index, 'required', checked)
                                                        }
                                                    />
                                                </div>
                                            </List.Item>
                                        ),
                                )}
                        </List>}
                    <Header content={source === "Menu" ? "Sides" : "Add-ons"} as="h5" dividing />
                    <div style={{ display: 'flex', gap: '10px', alignItems: 'center', padding: '15px 10px' }}>
                        <Icon
                            name="plus"
                            size="large"
                            onClick={handleAddItem}
                            style={{ cursor: "pointer" }}
                        />
                        <Input
                            placeholder="Item"
                            value={addon.itemName || ""}
                            onChange={(e) => setAddOn({ ...addon, ["itemName"]: e.target.value })}
                        />
                        <Input
                            placeholder="Item Cost"
                            min={0}
                            value={addon.itemCost || ""}
                            onChange={(e) => {
                                let newValue = parseFloat(e.target.value);

                                setAddOn({ ...addon, ["itemCost"]: newValue });
                            }}
                        />
                        <Checkbox
                            checked={addon.required || false}
                            onChange={(e, { checked }) => setAddOn({ ...addon, required: checked })}
                        />
                    </div>
                    {
                        (instanceData && instanceData._id && isEdit) &&
                        <Form.Field style={{marginTop: "20px"}}>
                            <Header content={"Event photos"} as="h5" dividing />
                            {instanceData && <SVCEventImageUploader
                                svcInstance={instanceData}
                                updateInstanceImageFilenames={(newImageFilenames: string[]) => {
                                    const localSelectedInstance = JSON.parse(JSON.stringify(instanceData));
                                    localSelectedInstance.imageFilenames = newImageFilenames;
                                    setInstanceData(localSelectedInstance);
                                }}
                            />}
                        </Form.Field>
                    }
                </Form>
                <Divider />
            </div>
            <Modal //confirmation modal
                dimmer={true}
                open={openConfirmModal}
                onClose={() => setOpenConfirmModal(false)}
                closeOnDimmerClick={true}
                className='center-self'
            >
                <Modal.Content>
                    <p>
                        {modifyGroup ? "You are about to delete all the recurrence data related to this service instance" : "Are you sure you want to delete this service instance ?"}
                    </p>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' onClick={() => {
                        setOpenConfirmModal(false);
                        handleDelete();
                    }}>
                        <Icon name='checkmark' /> Delete
                    </Button>
                    <Button onClick={() => { setOpenConfirmModal(false); }}>
                        <Icon name='remove' /> Cancel
                    </Button>
                </Modal.Actions>
            </Modal>
            <Modal
                open={recModal}
                onClose={() => {
                    setRecModal(false);
                    closeModal();
                }}
            >
                <Modal.Header>Edit recurring event?</Modal.Header>
                <Modal.Actions>
                    <Button
                        basic color='blue'
                        content="This event"
                        onClick={() => {
                            setModifyGroup(false);
                            setRecModal(false);
                            handleChangeInstanceData("recurrence", undefined);
                        }}
                    />
                    {/* Disabled all events as requested by client. For now I have commeted it so that if later they want it we can just un-comment this feature. */}
                    {/* <Button
                        basic color='red'
                        content="All events"
                        onClick={() => {
                            setModifyGroup(true);
                            setRecModal(false);
                        }}
                    /> */}
                    {
                        <Button
                            basic
                            color='yellow'
                            content="This and following events"
                            onClick={() => {
                                setSelectedThisAndFollowingDate(instanceData.startDate);
                                if (getThisAndFollowingDate) {
                                    getThisAndFollowingDate(instanceData.startDate);
                                }
                                setThisAndFollowingOptionSelected(true);
                                setRecModal(false);
                            }}
                        >
                            This and following events
                        </Button>
                    }
                    <Button
                        basic color='grey'
                        onClick={() => {
                            setRecModal(false);
                            closeModal();
                        }}>
                        Cancel
                    </Button>                
                </Modal.Actions>
            </Modal>
        </>
    );
}

export default ServiceInstanceFormCreate;