import { OrderIdColorDict, Request, RequestsDict } from "../types";
import { formatDateWithTZ } from "./timezone";
import moment from "moment";
import { RequestInstance } from "../types/RequestInstance";

export const formatRequestsDictForCalendarEvents = (requestsDict: RequestsDict) => {
    // its getting to complicated to make finalResult a type that is not any, we have to change many files , so for now we will leave it as any
    const finalResult: any[] = [];
    if (requestsDict && typeof requestsDict === "object" && !Array.isArray(requestsDict)) {
        for (const [date, categories = {}] of Object.entries(requestsDict)) {
            if (categories && typeof categories === "object" && Object.keys(categories).length && !Array.isArray(categories)) {
                for (const [category, count] of Object.entries(categories)) {
                    if (typeof count === "number") {
                        finalResult.push({
                            Name: `${category} - ${count}`,
                            ScheduledTime: {
                                startDate: date,
                                endDate: date,
                            },
                            _id: `${date}-${category}`,
                        });
                    } else {
                        console.error(`Invalid count for category ${category} on date ${date}`);
                    }
                }
            } else {
                console.error(`Invalid categories for date ${date}`);
            }
        }
    } else {
        console.error("Invalid input object");
    }
    return finalResult;
};

export const formatRequestsForCalendarEvents = (requestsDict: Record<string, any>, facilityTZ: string) => {
    const finalResult: any[] = [];

    if (requestsDict && typeof requestsDict === "object" && !Array.isArray(requestsDict)) {
        for (const [date, value] of Object.entries(requestsDict)) {
            if (Array.isArray(value)) {
                // Handle the array of objects case
                for (const request of value) {
                    if (request && typeof request === "object") {
                        const { Category, Department_Name, ScheduledTime = undefined, RequestedTime } = request;
                        if (Department_Name) {
                            const time = (ScheduledTime && moment(ScheduledTime.startDate).format('h:mm A')) || RequestedTime && formatDateWithTZ(RequestedTime, 'h:mm A', facilityTZ);
                            finalResult.push({
                                Name: `${Category || Department_Name}  ${time}`,
                                ScheduledTime: {
                                    startDate: date,
                                    endDate: date,
                                },
                                _id: request._id,
                                redirectToDetailedView: true,
                                request: request,
                                RequestedTime: RequestedTime,
                            });
                        } else {
                            console.error(`Invalid request data on date ${date}`, request);
                        }
                    } else {
                        console.error(`Invalid request object on date ${date}`, request);
                    }
                }
            } else if (value && typeof value === "object" && !Array.isArray(value)) {
                // Handle the nested object case
                for (const [category, count] of Object.entries(value)) {
                    if (typeof count === "number") {
                        finalResult.push({
                            Name: `${category} - ${count}`,
                            ScheduledTime: {
                                startDate: date,
                                endDate: date,
                            },
                            _id: `${date}-${category}`,
                        });
                    } else {
                        console.error(`Invalid count for category ${category} on date ${date}`);
                    }
                }
            } else {
                console.error(`Invalid value for date ${date}`);
            }
        }
    } else {
        console.error("Invalid input object");
    }

    return finalResult;
};

export function getOrderIdColor(orderIdColorDict: OrderIdColorDict, setAlreadyGeneratedColors: (items) => void, setOrderIdColorDict: (dict) => void, alreadyGeneratedColors: string[], orderId: string | undefined) {
    if (!orderId) {
        return;
    }
    // If the color already exists for the orderId, return it
    if (orderIdColorDict[orderId]) {
        return orderIdColorDict[orderId];
    }
    // Function to generate a valid color
    const generateColor = (): string => {
        // Generate a random color
        let randomColor = Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0');
        // Check if the color has already been generated
        if (alreadyGeneratedColors.includes(randomColor)) {
            return generateColor();
        }
        // Convert hex to RGB
        const r = parseInt(randomColor.substring(0, 2), 16);
        const g = parseInt(randomColor.substring(2, 4), 16);
        const b = parseInt(randomColor.substring(4, 6), 16);
        // Calculate brightness (perceived luminance)
        const brightness = 0.2126 * r + 0.7152 * g + 0.0722 * b;
        // Define the brightness thresholds
        const minBrightness = 50; // Avoid very dark colors (close to black)
        const maxBrightness = 200; // Avoid very bright colors (close to white)
        // Check if the color is within acceptable brightness range
        if (brightness > minBrightness && brightness < maxBrightness) {
            // Add the color to the already generated colors
            setAlreadyGeneratedColors(prevColors => [...prevColors, randomColor]);
            return `#${randomColor}`;
        }
        // Generate a new color if it doesn't meet the criteria
        return generateColor();
    };
    const newColor = generateColor();
    setOrderIdColorDict(prevMap => ({ ...prevMap, [orderId]: newColor }));
    return newColor;
};

export const renderRequestLabelColor = (req) => {
    if (req.Status === 'Open') {
        return '#DB2828';
    } else if (req.Status === 'Accepted') {
        return '#22BA46';
    } else if (req.ClosedByResident) {
        // if closed by resident we show its status in purple color
        return 'purple';
    } else {
        return 'grey';
    }
};

export const renderRequestStatus = (req) => {
    if (req.ClosedByResident) {
        // if closed by resident we show it as canceled
        return 'Canceled';
    } else {
        return req.Status;
    }
};

 export function groupRequests(requests: Request[] | RequestInstance[]): Request[] {
        const groupedRequests: Request[] = [];

        requests.forEach((currentRequest) => {
            let foundSimilar = false;

            // Loop through grouped requests to find a match
            for (const groupedRequest of groupedRequests) {
                if (
                    currentRequest.RequestChannel === "ExternalDevice" &&
                    groupedRequest.RequestChannel === "ExternalDevice" &&
                    groupedRequest.Registrant_FirstName === currentRequest.Registrant_FirstName &&
                    groupedRequest.Registrant_LastName === currentRequest.Registrant_LastName &&
                    groupedRequest.Status === "Open"
                ) {
                    // If similar request is found, group
                    if (!groupedRequest.similarRequestIds || !Array.isArray(groupedRequest.similarRequestIds) || groupedRequest.similarRequestIds.length === 0) {
                        groupedRequest.similarRequestIds = [groupedRequest._id];
                        groupedRequest.requestCount = 1;
                    }
                    groupedRequest.similarRequestIds.push(currentRequest._id);
                    groupedRequest.requestCount! += 1; // ! is used to tell typescript that this value will never be null
                    foundSimilar = true;
                    break;
                }
            }

            // If no similar request was found, add it as a new grouped request
            if (!foundSimilar) {
                groupedRequests.push({ ...currentRequest });
            }
        });

        return groupedRequests;
    }

const extractDescription = (str) => {
    const colonCount = (str.match(/:/g) || []).length;
    if (colonCount > 0) {
        if (str.includes("Ride:") || str.includes("place:") || str.includes("time:") || str.includes("day:") || str.includes("Dietary") || str.includes("shopping:") || str.includes("Fallen:")) {
            let formattedText = str
                .replace(/Ride:ride\s*/i, '') // Remove "Ride:ride"
                .replace(/place:place\s*/i, '') // Remove "place:place"
                .replace(/place:(\w+)/i, (match, p1) => `Place: ${p1.charAt(0).toUpperCase() + p1.slice(1)}`) // Capitalize place name
                .replace(/shopping:(\w+)/i, (match, p1) => `Place: ${p1.charAt(0).toUpperCase() + p1.slice(1)}`) // Capitalize place name
                .replace(/Ride:(\w+)/i, (match, p1) => `Vehicle: ${p1.charAt(0).toUpperCase() + p1.slice(1)}`) // Capitalize place name
                .replace(/Fallen:(\w+)/i, (match, p1) => `Incident: ${p1.charAt(0).toUpperCase() + p1.slice(1)}`) // Capitalize place name
                .replace(/time:(\d{2}):(\d{2})/i, (match, hour, minute) => {
                    const hours = parseInt(hour, 10);
                    const suffix = hours >= 12 ? "PM" : "AM";
                    const adjustedHour = hours % 12 || 12;
                    return `Time: ${adjustedHour}:${minute} ${suffix}`;
                })
                .replace(/day:(\d{2})-(\d{2})-(\d{4})/i, (match, month, day, year) => `Date: ${month}/${day}/${year}`)
                .replace(/Dietary:(\w+)/i, (match, p1) => `Dietary: ${p1.charAt(0).toUpperCase() + p1.slice(1)}`)
                .replace(/description:(\w+)/i, (match, p1) => `Description: ${p1.charAt(0).toUpperCase() + p1.slice(1)}`);
            formattedText = formattedText.replace(/^[,\s]+|[,\s]+$/g, '');
            return formattedText;
        }
        const match = str.match(/description:([^,]+)/);
        return match ? match[1].trim() : str;
    }
    const descKeyword = "description:";
    const descIndex = str.indexOf(descKeyword);
    if (descIndex !== -1) {
        const descPart = str.slice(descIndex + descKeyword.length).trim();
        return descPart;
    }
    return str;
}    

export const handleRequestTest = (request, key: string) => {
    if (key === 'Details') {
        if (request.Details) {
            const word = extractDescription(request.Details);
            return word.replace(/,$/, '');
        }
    }
    if (key === 'Name') {
        if (request.Name && request.Name.includes('Nursing-Assistance')) {
            return request.Name.replace('Nursing-', '');
        } else {
            return request.Name;
        }
    }
};

export const requestListView = 'list';
export const requestCalendarView = 'calendar';
export const requestSortOptions = [{ key: "asc", value: "desc", text: "Oldest" }, { key: "desc", value: "asc", text: "Newest" }]; // The backend is faulty. If we send descending we get ascending values and if we send ascending we get descending value so we have shifted it