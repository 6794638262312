import API from './API'
import { fetchRequests } from './Requests'
import { fetchRequests as fetchBasicRequests } from "./Basics"
import { ReportsSummary } from '../types'
import { FetchRequestsResponse } from '../types'

export async function fetchReportsSummary(
    fromDate: Date = new Date(),
    toDate: Date = new Date(),
    staff: string | null = null,
    resident: string | null = null,
    department: string | null | string [] = null,
    disable : boolean | null = null, 
    facilityTimezone: string = "",
): Promise<ReportsSummary> {
    const staffId = staff === 'all' ? null : staff
    const residentId = resident === 'all' ? null : resident
    const departmentIds = department === 'all' ? null : department
    const [
        { count: activeRequestCount },
        { count: escalatedRequestCount },
        { count: resolvedRequestCount },
        { count: residentsAttendedCount }
    ] = await Promise.all([
        fetchRequests('active', fromDate, toDate, null, staffId, residentId, departmentIds, disable, 1, 1, undefined, undefined, facilityTimezone),
        fetchRequests('escalated', fromDate, toDate, null, staffId, residentId, departmentIds, disable, 1, 1, undefined, undefined, facilityTimezone),
        fetchRequests('resolved', fromDate, toDate, null, staffId, residentId, departmentIds, disable, 1, 1, undefined, undefined, facilityTimezone),
        fetchBasicRequests(fromDate, toDate, resident !== "all" ? resident : null, null, 'Daily Activities Attendance', null, 1, 100, [{ RequestedTime: 'asc' }]) // disable param = true is returning nothing hence making it null
    ])

    return {
        Active: activeRequestCount,
        Escalated: escalatedRequestCount,
        Resolved: resolvedRequestCount,
        TotRequests: activeRequestCount + resolvedRequestCount || 1,
        ResidentsAttendedCount: residentsAttendedCount
    }
}

function parseFetchResponse(response: any, page: number = 1, limit: number = 100, staff) {
    const res: FetchRequestsResponse = {
        count: response.TotRecords || 0,
        page,
        limit,
        requests: response.Result.map(e => ({
            ...e,
            ClosedBy: e.ClosedBy && staff[e.ClosedBy],
        })),
    }
    return res
}

// Active
async function fetchStaffForRequest() {
    const res = await API.lambdaGet('/users/list')
    console.log(res)
    const { Result } = res

    return Result.reduce((accl, user) => {
        return { ...accl, [user._id]: `${user.FirstName} ${user.LastName}` }
    }, {})
}

/** Temporary functions, the code to be reused from requesuts after anil makes his changes */
export async function downloadRequestReport(filterType, sortBy, page) {
    const filters = {
        active: {
            Status: { $ne: 'Closed' },
        },
        escalated: {
            EscalatedTo: { $exists: true },
            Status: 'Open',
        },
        resolved: {
            Status: 'Closed',
        },
    }
    const [res, staff] = await Promise.all([
        API.lambdaPost('/requests/list', {
            Filter: {
                ...filters[filterType],
            },
            QueryOptions: {
                sort: sortBy,
                page_no: page,
                page_size: 100,
            },
        }),

        fetchStaffForRequest(),
    ])

    const parsed = parseFetchResponse(res, page, 100, staff)

    return { reports: parsed, pages: Math.ceil(parsed.count / 100) }
}

export async function fetchGenericRequestReports(filters, pageIndex, pageSize, sortBy) {
    const [res, staff] = await Promise.all([
        API.lambdaPost('/requests/list', {
            Filter: {
                ...filters,
            },
            QueryOptions: {
                page_no: pageIndex,
                page_size: pageSize,
                sort: sortBy,
            },
        }),

        fetchStaffForRequest(),
    ])

    const parsed = parseFetchResponse(res, pageIndex, pageSize, staff)

    return parsed
}

export async function fetchRequestReports(filterType, pageIndex = 1, pageSize = 40, sortBy) {
    const filters = {
        active: {
            Status: { $ne: 'Closed' },
        },
        escalated: {
            EscalatedTo: { $exists: true },
            Status: 'Open',
        },
        resolved: {
            Status: 'Closed',
        },
    }

    return fetchGenericRequestReports(filters[filterType], pageIndex, pageSize, sortBy)
}

// export async function fetchActiveRequestReports(pageIndex, pageSize, sortBy) {
//     const res = await API.lambdaPost('/requests/list', {
//         Filter: {
//             Status: { $ne: 'Closed' },
//         },
//         QueryOptions: {
//             page_no: pageIndex,
//             page_size: pageSize,
//             sort: sortBy,
//         },
//     })

//     const parsed = parseFetchResponse(res, pageIndex, pageSize)

//     return parsed
// }

//Escalated

//Resolved

//Negative
