import React, { Component, ChangeEvent } from 'react'
import { Dropdown, Input, Popup } from 'semantic-ui-react'

import './style.less'
import { User } from '../../types'
import { sendToast } from '../../util';

interface Props {
    users: User[]
    loading?: boolean
    disabled?: boolean
    onSelectionChange?: (u: User | null) => void
    locationState?: boolean
    customButtonText?: string | null
    source?: string
}

interface State {
    searchInput: string,
    dropdownStyle: {},
    dropdownOpen: boolean,
    dropdownText: string
}
class AssignButton extends Component<Props, State> {
    private dropdownRef: React.RefObject<HTMLDivElement>; // Declare the dropdownRef
    constructor(props: Props) {
        super(props)
        this.state = {
            searchInput: '',
            dropdownStyle: { Visibility: 'hidden', opacity: 0, zIndex: -1 , display: 'none'},
            dropdownOpen: false,
            dropdownText: this.props.customButtonText ? this.props.customButtonText : this.props.locationState ? 'Assign All' : 'Assign'
        }
        this.dropdownRef = React.createRef(); // Create a ref for the dropdown
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleOutsideClick);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleOutsideClick);
    }

    handleOutsideClick = (event: MouseEvent) => {
        if (
            this.state.dropdownOpen &&
            this.dropdownRef.current &&
            !this.dropdownRef.current.contains(event.target as Node)
        ) {
            this.setState({
                dropdownOpen: false,
                dropdownStyle: { visibility: 'hidden', opacity: 0, zIndex: -1, display: 'none' },
            });
        }
    };

    handleChange(e: ChangeEvent<HTMLInputElement>) {
        this.setState({
            searchInput: e.currentTarget.value,
            dropdownStyle: { Visibility: 'visible', opacity: 1, zIndex: 1, position: "absolute", top: this.props.source === "requestStandalone" ? "280%" : "200px", left: 0 },
        }, () => {
            if (this.state.searchInput === "") {
                this.setState({
                    dropdownStyle: { Visibility: 'visible', opacity: 1, zIndex: 1, position: "absolute", top: this.props.source === "requestStandalone" ? "400%" : "330px", left: 0 },
                   
                })
            }
        })
    }

    getFilteredUsersOptions() {
        return this.props.users
            .filter(u => {
                const fullName = (u.FirstName + ' ' + u.LastName).toLowerCase()
                return fullName.includes(this.state.searchInput.toLowerCase())
            })
            .map(u => ({
                key: u._id,
                text: u.FirstName + ' ' + u.LastName,
                value: u._id,
                image: { avatar: true, src: u.Icon || `${process.env.PUBLIC_URL}/avatar_placeholder.svg` },
                disabled : u.onBreak 
            })).sort((a, b) => a.text.localeCompare(b.text))
    }

    handleSelect(_: any, data: any) {
        if (!this.props.onSelectionChange) return
        const user = this.props.users.find(u => u._id === data.value) || null
        this.props.onSelectionChange(user)
        this.setState({
            searchInput: '',
            dropdownStyle: { Visibility: 'hidden', opacity: 0, zIndex: -1, display: 'none' },
            dropdownOpen: false,
            dropdownText: user && user.FirstName + ' ' + user.LastName || ""
        })
    }

    showStaffDropdown = () => {
        if (this.props.loading || this.props.disabled) {
            sendToast('message', 'Please make sure the request is not closed in order to assign staff');
            return;
        }
        this.setState((prevState) => {
            return {
                dropdownStyle: { Visibility: 'visible', opacity: 1, zIndex: 1, position: "absolute", top: this.props.source === "requestStandalone" ? "400%" : "330px", left: 0},
                dropdownOpen: !prevState.dropdownOpen
            }
        })
    }

    render() {
        return (
            (this.props.source === 'request' || this.props.source === "requestStandalone") ?
                <Popup content={this.state.dropdownText} 
                disabled={this.state.dropdownText === "Assign" || this.state.dropdownText === ""}
                trigger={
                    <div onClick={() => this.showStaffDropdown()}>
                        <div className='edit-request-btn'>
                        <div className='userIcon'>
                            <i className="fa-solid fa-user-check"></i>
                        </div>
                            {(this.state.dropdownText !== "Assign" && this.state.dropdownText.slice(0, 6).trim()) || this.state.dropdownText}{(this.state.dropdownText !== "Assign" && this.state.dropdownText.length > 6) ? '...' : ''}
                        </div>
                        <div ref={this.dropdownRef}>
                            <Dropdown
                                scrolling
                                fluid
                                labeled
                                button
                                basic
                                loading={this.props.loading}
                                disabled={this.props.loading || this.props.disabled}
                                style={this.state.dropdownStyle}
                                open={this.state.dropdownOpen}
                                className='assignDropdown'
                                onClick={(e) => e.stopPropagation()} // Prevent parent click handler
                            >
                                <Dropdown.Menu>
                                    <Input
                                        icon="search"
                                        iconPosition="left"
                                        className="search"
                                        onClick={(e: React.MouseEvent) => e.stopPropagation()}
                                        value={this.state.searchInput}
                                        onChange={this.handleChange.bind(this)}
                                    />
                                    <Dropdown.Divider />
                                    <Dropdown.Header content="Assign To" />
                                    {this.getFilteredUsersOptions().map(option => (
                                        <Dropdown.Item {...option} onClick={this.handleSelect.bind(this)} />
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                }
                />
                : (
                <Dropdown
                    scrolling
                    text={this.props.customButtonText ? this.props.customButtonText : this.props.locationState ? 'Assign All' : 'Assign'}
                    icon="user"
                    fluid
                    labeled
                    button
                    basic
                    className="icon button primary AssignButton"
                    loading={this.props.loading}
                    disabled={this.props.loading || this.props.disabled}
                >
                    <Dropdown.Menu>
                        <Input
                            icon="search"
                            iconPosition="left"
                            className="search"
                            onClick={(e: Event) => e.stopPropagation()}
                            value={this.state.searchInput}
                            onChange={this.handleChange.bind(this)}
                        />
                        <Dropdown.Divider />
                        <Dropdown.Header content="Assign To" />
                        {this.getFilteredUsersOptions().map(option => (
                            <Dropdown.Item {...option} onClick={this.handleSelect.bind(this)} />
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            )
        )
    }
}

export default AssignButton
