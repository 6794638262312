import { useState, useEffect, useMemo } from 'react';
import { fetchRequests } from '../services/Requests';
import { AuthState, Request } from '../types';
import { registerOnNotificationHandler } from '../services/PushNotifications';
import { ReportSummaryFilters } from '../types';
import { useSelector } from 'react-redux';

const REFRESH_REQUESTS_TIME_MS = 30000;

export const useAdminPanelRequests = (
    filters: ReportSummaryFilters,
    sortBy: object[] | undefined,
    shouldBG = false,
    isReport = false,
    pageNo = 1,
    pageSize = 40,
    history,
    fetchDepartmentAndCategoryCountForCalendar = false, // todo this is only used in calendar view, and currently calendar view support count fetch instead of all data, we should rename this accordingly.
    status = "",
    fetchAllReq = false,
    userId: string = '',
    fetchAllGroupsReq = false,
) => {
    const [requests, setRequests] = useState<Request[]>([]);
    const [error, setError] = useState();
    const [pageCount, setPageCount] = useState(1);
    const [totalDataLength, settotalDataLength] = useState<number>(0);
    const [isFetching, setIsFetching] = useState(false);
    const [isPaginating, setIsPaginating] = useState(false);
    const [hasMorePages, setHasMorePages] = useState(true);
    const [requestsCategoryDepCountBasedOnDateDict, setRequestsCategoryDepCountBasedOnDateDict] = useState({});
    const profile = useSelector(({ authReducer }: { authReducer: AuthState; }) => {
        return authReducer.profile;
    });
    const facilityTZ = profile && profile.FacilityTimeZone || "";

    const memoizedSortBy = useMemo(() => sortBy, [JSON.stringify(sortBy)]); // avoids infinite loop in useEffect

    const loadRequests = async (
        requestFilters: ReportSummaryFilters,
        requestPageNo: number,
        requestPageSize: number,
        requestSortBy: Object[] | undefined,
        isBG: boolean,
        isRequestPaginating: boolean,
    ) => {
        if (!isBG && !isRequestPaginating) {
            setRequests([]);
            setIsFetching(true);
        }

        if (isRequestPaginating) {
            setIsPaginating(true);
        }
        if (fetchDepartmentAndCategoryCountForCalendar) {
            fetchRequests(
                requestFilters.requestType,
                requestFilters.fromDate,
                requestFilters.toDate,
                userId,
                requestFilters.staff === 'all' ? null : requestFilters.staff,
                requestFilters.resident === 'all' ? null : requestFilters.resident,
                requestFilters.department === 'all' ? null : requestFilters.department,
                requestFilters.disable === true ? true : null,
                requestPageNo,
                requestPageSize,
                requestSortBy,
                status,
                facilityTZ,
                true,
                undefined,
                requestFilters.group === 'all' ? undefined : requestFilters.group,
                fetchAllGroupsReq
            ).then(({ count, requests: countResponse }) => {
                setRequestsCategoryDepCountBasedOnDateDict(countResponse);
                setIsFetching(false);
                setIsPaginating(false);
                setPageCount(Math.ceil(count / requestPageSize));
                setHasMorePages(false);
                settotalDataLength(count);
            });
            return;
        }

        return fetchRequests(
            requestFilters.requestType,
            requestFilters.fromDate,
            requestFilters.toDate,
            userId,
            requestFilters.staff === 'all' ? null : requestFilters.staff,
            requestFilters.resident === 'all' ? null : requestFilters.resident,
            requestFilters.department === 'all' ? null : requestFilters.department,
            requestFilters.disable === true ? true : null,
            requestPageNo,
            requestPageSize,
            requestSortBy,
            status,
            facilityTZ,
            false,
            requestFilters.FetchCancelRequests,
            requestFilters.group === 'all' ? undefined : requestFilters.group,
            fetchAllGroupsReq
        ).then(({ count, requests: freshRequests }) => {
            if (fetchAllReq && (count > freshRequests.length) && (count > 0)) {
                return fetchRequests(
                    requestFilters.requestType,
                    requestFilters.fromDate,
                    requestFilters.toDate,
                    userId,
                    requestFilters.staff === 'all' ? null : requestFilters.staff,
                    requestFilters.resident === 'all' ? null : requestFilters.resident,
                    requestFilters.department === 'all' ? null : requestFilters.department,
                    requestFilters.disable === true ? true : null,
                    1,
                    count,
                    requestSortBy,
                    status,
                    facilityTZ,
                    false,
                    requestFilters.FetchCancelRequests,
                    requestFilters.group === 'all' ? undefined : requestFilters.group,
                    fetchAllGroupsReq
                ).then(({ requests: freshRequests }) => {
                    setRequests(freshRequests);
                    setIsFetching(false);
                    setIsPaginating(false);
                    setPageCount(Math.ceil(count / requestPageSize));
                    setHasMorePages(count >= requestPageSize * requestPageNo);
                    settotalDataLength(count);
                });
            } else {
                setRequests(freshRequests);
                setIsFetching(false);
                setIsPaginating(false);
                setPageCount(Math.ceil(count / requestPageSize));
                setHasMorePages(count >= requestPageSize * requestPageNo);
                settotalDataLength(count);
            }
        });
    };

    useEffect(() => {
        const requestPageSize = isReport ? pageSize : pageSize * pageNo;
        const requestPageNo = isReport ? pageNo : 1;
        loadRequests(filters, requestPageNo, requestPageSize, memoizedSortBy, false, pageNo !== 1);
    }, [filters, memoizedSortBy, isReport, pageSize, pageNo, fetchDepartmentAndCategoryCountForCalendar, status, fetchAllGroupsReq]);

    useEffect(() => {
        if (shouldBG) {
            const bGFunction = () => {
                const requestPageSize = isReport ? pageSize : pageSize * pageNo;
                const requestPageNo = isReport ? pageNo : 1;
                loadRequests(filters, requestPageNo, requestPageSize, memoizedSortBy, true, false);
            };
            const unsubscribeHandler = registerOnNotificationHandler(bGFunction, history);
            const timerId = window.setInterval(bGFunction, REFRESH_REQUESTS_TIME_MS);

            return () => {
                if (timerId) window.clearInterval(timerId);
                if (unsubscribeHandler) unsubscribeHandler();
            };
        }
    }, [filters, memoizedSortBy, isReport, pageSize, pageNo, shouldBG, fetchDepartmentAndCategoryCountForCalendar, status, fetchAllGroupsReq]);

    return {
        requests,
        error,
        isFetching,
        isPaginating,
        hasMorePages,
        pageCount,
        totalDataLength,
        requestsCategoryDepCountBasedOnDateDict
    };
};
