import React, { useEffect, useState } from 'react';
import { Button, Dimmer, Grid, Icon, Loader, Modal } from 'semantic-ui-react';
import './style.less';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { updateRequestInstance } from '../../services/RequestInstances';
import { toast } from 'react-toastify';
import { acceptRequest } from '../../services/Requests';
import { getDurationInMs, getFormattedEndDateString } from '../../util/timezone';
import { RequestInstance } from '../../types/RequestInstance';

interface Props {
    id: string;
    request: RequestInstance;
    refresh?: () => void;
    powerAndFacilityUsers?: boolean;
    datePickerModal: boolean;
    setDatePickerModal: (value: boolean) => void;
}

const RescheduleRequestModal: React.FC<Props> = ({
    request,
    id,
    refresh,
    powerAndFacilityUsers,
    datePickerModal,
    setDatePickerModal,
}) => {
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [modalLoader, setmodalLoader] = useState<boolean>(false);
    const [duration, setDuration] = useState<number | undefined>(undefined);

    useEffect(() => {
        if (request.ScheduledTime) {
            setStartDate(request.ScheduledTime.startDate || moment().format('DD-MM-YYYY'));
            setEndDate(request.ScheduledTime.endDate || moment().format('DD-MM-YYYY'));
        } else {
            setStartDate('');
            setEndDate('');
        }
    }, [datePickerModal]);

    useEffect(() => {
        if (startDate && duration !== undefined) {
            const updatedEndDate = getFormattedEndDateString(startDate, duration, 'YYYY-MM-DDTHH:mm:ss');
            setEndDate(updatedEndDate);
        }
    }, [startDate]);

    useEffect(() => {
        if (endDate) {
            // here there is no check for start date, as it will be always there when end date is there
            const updateDuration = getDurationInMs(startDate, endDate);
            setDuration(updateDuration);
        }
    }, [endDate]);

    const handleCloseModal = () => {
        setStartDate('');
        setEndDate('');
        setDatePickerModal(false);
    };
    const handleSubmit = async (accept: boolean = false) => {
        try {
            setmodalLoader(true);
            if (startDate >= endDate) {
                throw new Error('Start time should not be greator than end time');
            }
            if (startDate === 'Invalid date' || endDate === 'Invalid date') {
                toast.warn('Please select a valid start/end date', {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                return;
            }

            const ScheduledTime = {
                startDate,
                endDate,
            };
            const requestInstance = {
                _id: id,
                ScheduledTime,
            };
            const res = await updateRequestInstance(requestInstance);
            if (!res.updated) {
                // backend will return updated as false with a message as to why updated is false
                return toast.warn(res.message ? res.message : 'Something went wrong while updating request', {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
            if (accept) {
                await acceptRequest(id);
            }
            toast.success('Successfully scheduled the request', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            handleCloseModal();
            refresh && refresh();
        } catch (err) {
            console.log(err);
            toast.error(err instanceof Error ? err.message : 'Failed to update Schedule', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } finally {
            setmodalLoader(false);
        }
    };

    return (
        <Modal onClose={() => setDatePickerModal(false)} open={datePickerModal}>
            <Dimmer active={modalLoader} inverted>
                <Loader active={modalLoader} />
            </Dimmer>
            <Modal.Header>Schedule the request</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Grid columns={2} centered>
                        <Grid.Column>
                            <label>Start Date</label>
                            <DatePicker
                                customInput={
                                    <div
                                        style={{
                                            position: 'relative',
                                            boxShadow: '0px 1px 2px 0 rgba(34, 36, 38, 0.15)',
                                        }}
                                    >
                                        <Icon
                                            name="calendar alternate outline"
                                            style={{
                                                position: 'absolute',
                                                top: '45%',
                                                left: '10px',
                                                transform: 'translateY(-50%)',
                                            }}
                                        />
                                        <input
                                            style={{ padding: '10px', paddingLeft: '30px' }}
                                            placeholder="Schedule Start *"
                                            required={true}
                                            value={startDate ? moment(startDate).format('M.D.Y h:mm A') : ''}
                                        />
                                    </div>
                                }
                                dateFormat="M.d.Y h:mm aa"
                                minDate={new Date()} // Set the minimum to today
                                showTimeSelect
                                timeIntervals={15}
                                selected={startDate ? Date.parse(startDate || new Date().toISOString()) : null}
                                onChange={(date: Date) => {
                                    if (!date) return;
                                    const dateTime = moment(date).format('YYYY-MM-DDTHH:mm:ss');
                                    setStartDate(dateTime);
                                    if (!endDate) {
                                        setEndDate(moment(dateTime).add(15, 'minutes').format('YYYY-MM-DDTHH:mm:ss'));
                                    }
                                }}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <div>
                                <label>End Date</label>
                                <DatePicker
                                    customInput={
                                        <div
                                            style={{
                                                position: 'relative',
                                                boxShadow: '0px 1px 2px 0 rgba(34, 36, 38, 0.15)',
                                            }}
                                        >
                                            <Icon
                                                name="calendar alternate outline"
                                                style={{
                                                    position: 'absolute',
                                                    top: '45%',
                                                    left: '10px',
                                                    transform: 'translateY(-50%)',
                                                }}
                                            />
                                            <input
                                                style={{ padding: '10px', paddingLeft: '30px' }}
                                                placeholder="Schedule End*"
                                                required={true}
                                                disabled={!startDate}
                                                value={endDate ? moment(endDate).format('M.D.Y h:mm A') : ''}
                                            />
                                        </div>
                                    }
                                    minDate={startDate ? new Date(startDate) : new Date()} // Set the minimum to today or start date
                                    minTime={
                                        startDate ? new Date(moment(startDate).add(15, 'minutes').valueOf()) : null
                                    } // Set the minTime her
                                    maxTime={
                                        // this is required when minTime is passed
                                        startDate
                                            ? new Date(startDate).setHours(23, 59, 59) // Set the maxTime to end of the day
                                            : null
                                    }
                                    dateFormat="M.d.Y h:mm aa"
                                    showTimeSelect
                                    disabled={!startDate}
                                    timeIntervals={15}
                                    selected={endDate ? Date.parse(endDate || new Date().toISOString()) : null}
                                    onChange={(date: Date) => {
                                        if (!date) return;
                                        const dateTime = moment(date).format('YYYY-MM-DDTHH:mm:ss');
                                        setEndDate(dateTime);
                                    }}
                                />
                            </div>
                        </Grid.Column>
                    </Grid>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => handleCloseModal()}>Cancel</Button>
                {powerAndFacilityUsers && (
                    <Button color={'blue'} onClick={() => handleSubmit(false)}>
                        {request.ScheduledTime ? 'Reschedule' : 'Schedule'}
                    </Button>
                )}
                {!request.StaffId && !powerAndFacilityUsers && (
                    <Button color={'blue'} onClick={() => handleSubmit(true)}>
                        {request.ScheduledTime ? 'Reschedule & Accept' : 'Schedule & Accept'}
                    </Button>
                )}
            </Modal.Actions>
        </Modal>
    );
};

export default RescheduleRequestModal;
