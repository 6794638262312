import React, { useState, useEffect, useRef } from 'react';
import { Promise } from 'bluebird';
import { Icon, Modal, Progress, Dimmer, Button, Popup, Dropdown, Loader } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import { Request, UserProfile } from '../../types';
// import RadialDropdown from '../RadialDropdown' they are deprecated
// import RadialProgress from '../RadialProgress'
import { fetchReportsSummary } from '../../services/Reports';
import { canManageRequests } from '../../services/Permissions';
import { closeRequest } from '../../services/Requests';
import { fetchAllActiveDepartments } from '../../services/Departments';
import { fetchRegistrants } from '../../services/Registrants';
import { addUserFavoriteDepartment, fetchAllActiveUsers, getUserFavoriteDepartments, removeUserFavoriteDepartment } from '../../services/Users';
import 'react-datepicker/dist/react-datepicker.css';
import './style.less';
import { User, Registrant, Department, ReportSummaryFilters, BasicReportSummaryFilters } from '../../types';
import moment from 'moment-timezone';
import CreateRequestModal from '../Requests/CreateRequestModal';
import { sortByKey } from '../../util/sortData';
import { toast } from 'react-toastify';
import { sendToast } from '../../util';
import { Group } from '../../types/Group';
import { listGroups } from '../../services/Groups';

interface Props {
    filters: any[];
    handleFilterChangeAssignable: (newFilters: ReportSummaryFilters) => void;
    handleFilterChangeBasic: (newFilters: BasicReportSummaryFilters) => void;
    shouldCreateRequestBeShown: Boolean;
    history: any;
    requests?: Request[];
    profile?: UserProfile | null;
    isFetching?: boolean;
    importedBy?: string; // Pass importedBy = "Requests" to render all the elements of this page
    blockCloseInView?: boolean; // Pass blockCloseInView = true to block the close in view onClick event
}

const ReportSummaryAll: React.SFC<Props> = ({
    filters,
    handleFilterChangeAssignable,
    handleFilterChangeBasic,
    shouldCreateRequestBeShown,
    history,
    requests,
    profile,
    isFetching,
    importedBy,
    blockCloseInView,
}) => {
    const [assignableRequestFilters, basicRequestFilters] = filters;
    const [active, setActive] = useState(0);
    const [escalated, setEscalated] = useState(0);
    const [resolved, setResolved] = useState(0);
    const [total, setTotal] = useState(1);
    const [error, setError] = useState('');
    const [percentages, setPercentages] = useState({
        active: 0,
        escalated: 0,
        resolved: 0,
        residentsAttended: 0,
    });
    const [staffOptionsList, setStaffOptionsList] = useState<User[]>([]);
    const [residentsOptionsList, setResidentsOptionsList] = useState<Registrant[]>([]);
    const [departmentsOptionsList, setDepartmentsOptionsList] = useState<Department[]>([]);
    const [groupsOptionsList, setGroupsOptionsList] = useState<Group[]>([]);
    const [basicRequestOptionsList, setBasicRequestOptionsList] = useState<string[]>(['all']);
    const [loadingFilters, setLoadingFilters] = useState<boolean>(true);
    const [shouldCloseAllBeShown, setShouldCloseAllBeShown] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [totalRequestsTobeDeleted, setTotalRequestsTobeDeleted] = useState(1);
    const [deletedRequests, setDeletedRequests] = useState(0);
    const [dates, setDates] = useState({
        fromDate: assignableRequestFilters.fromDate,
        toDate: assignableRequestFilters.toDate,
    });
    const [resident, setResident] = useState('all');
    const [residentsAttended, setResidentsAttended] = useState(0);
    const [openReqCreateModal, setOpenReqCreateModal] = useState(false);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const calendarRef = useRef<HTMLHeadingElement>(null);
    const [isAttendanceFilterSelected, setIsAttendanceFilterSelected] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [favorites, setFavorites] = useState<string[]>([]); // Track favorite departments
    const [isDepartmentLoading, setIsDepartmentLoading] = useState(false);
    const [loadingDepartments, setLoadingDepartments] = useState<string | null>(null);
    const [isDateChangedManually, setIsDateChangedManually] = useState<boolean>(false);

    useEffect(() => {
        const loadReportSummary = async () => {
            try {
                setError('');

                const res = await fetchReportsSummary(
                    assignableRequestFilters.fromDate,
                    assignableRequestFilters.toDate,
                    assignableRequestFilters.staff,
                    assignableRequestFilters.resident,
                    assignableRequestFilters.department,
                    assignableRequestFilters.disable,
                );
                if (res.TotRequests === 0) res.TotRequests = 1; // avoid division by 0

                console.log({ res });

                setActive(res.Active);
                setEscalated(res.Escalated);
                setResolved(res.Resolved);
                setTotal(res.TotRequests);
                setResidentsAttended(res.ResidentsAttendedCount);
            } catch (e) {
                if (e instanceof Error) {
                    setError(e.message);
                }
            }
        };
        if (importedBy !== 'Requests' && importedBy !== 'ReportsLiveRequests')
            loadReportSummary();
    }, [
        assignableRequestFilters.fromDate,
        assignableRequestFilters.toDate,
        assignableRequestFilters.staff,
        assignableRequestFilters.resident,
        assignableRequestFilters.department,
        assignableRequestFilters.reload,
    ]);

    useEffect(() => {
        profile && setShouldCloseAllBeShown(canManageRequests(profile));
        const IncludeNonResidentialRoomOccupants = true;
        const populateFilters = () => {
            Promise.all([fetchAllActiveUsers(), fetchRegistrants( { IncludeNonResidentialRoomOccupants }), fetchAllActiveDepartments(), listGroups()]).then(
                ([staffList, residentsList, departmentsList, groupsList]) => {
                    setStaffOptionsList(staffList);
                    setResidentsOptionsList(residentsList);
                    setDepartmentsOptionsList(departmentsList);
                    setGroupsOptionsList(groupsList);
                    setLoadingFilters(false);
                },
            );
        };
        populateFilters();
    }, []);

    useEffect(() => {
        setPercentages({
            active: (active / total) * 100,
            resolved: (resolved / total) * 100,
            escalated: (escalated / total) * 100,
            residentsAttended: (residentsAttended / total) * 100,
        });
    }, [active, resolved, escalated, total]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (calendarRef.current && !calendarRef.current.contains(event.target)) {
                setShowDatePicker(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [calendarRef]);

    useEffect(() => {
        const fetchFavoritesAndSetFilters = async () => {
            try {
                const userId = profile && profile._id;
                if (userId) {
                    setIsDepartmentLoading(true);
                    const response = await getUserFavoriteDepartments(String(userId)); // Fetch from API
                    console.log("response: ", response);
                    if (response && response.departmentIds) {
                        setFavorites(response.departmentIds); // Set the favorite departments
                        
                        if (response.departmentIds.length > 0) {
                            // Update the filters to show favorite department requests
                            handleFilterChangeAssignable({
                                ...assignableRequestFilters,
                                department: response.departmentIds, // Set the first favorite department as default
                            });
                        }
                    }
                }
            } catch (error) {
                console.log("Something went wrong while fetching favorites: ", error);
                sendToast('error', "Failed to fetch favorite departments");
            } finally {
                setIsDepartmentLoading(false);
            }
        };
    
        fetchFavoritesAndSetFilters();
    }, [profile]);
    
    

    const delay = (ms) => new Promise((res) => setTimeout(res, ms));

    const createReqBtnCilckHandler = () => {
        setOpenReqCreateModal(true);
    };

    const closeAll = async (otherRequestIds: string[] = [], deliveryRequestIds: string[] = []) => {
        try {
            let counter = 0;
            setTotalRequestsTobeDeleted(otherRequestIds.length + deliveryRequestIds.length);
            setIsModalOpen(true);
            // mapping over all otherRequestIds and deliveryRequestIds seperately as we have to first close all the other requests and then delivery requests.
            await Promise.map(
                otherRequestIds,
                async (requestId: string) => {
                    await closeRequest(requestId);
                    counter += 1;
                    setDeletedRequests(counter);
                },
                { concurrency: 5 },
            );
            await Promise.map(
                deliveryRequestIds,
                async (requestId: string) => {
                    await closeRequest(requestId);
                    counter += 1;
                    setDeletedRequests(counter);
                },
                { concurrency: 5 },
            );
            await delay(500);
            handleFilterChangeAssignable({ ...assignableRequestFilters, reload: assignableRequestFilters.reload + 1 });
            setIsModalOpen(false);
            setDeletedRequests(0);
            setTotalRequestsTobeDeleted(1);
            toggleConfirmationModal();
        } catch (error) {
            sendToast('error', error instanceof Error ? error.message : `Failed to close all the requests`);
        }
    };

    const handleFilterChange = (type, payload, caseWhenAssignableActive: string | null = null) => {
        if (payload.department && payload.department.length === 0) {
            payload.department = 'all';
            assignableRequestFilters.department = 'all';
        } else if ( payload.department && payload.department.length === 1 && payload.department[0] === 'all') {
            payload.department = 'all';
            assignableRequestFilters.department = 'all';
        } // if payload.department is an array with only ['a', 'l'] in it, set it to 'all'
        else if (Array.isArray(payload.department) && payload.department && payload.department.includes('a') && payload.department.includes('l')) {
            // remove [a] [l] from the array and set it to 'all' 
            console.log('payload.department: ', payload.department);
            payload.department = payload.department.filter((item) => item !== 'a' && item !== 'l');
            payload.department = 'all';
            assignableRequestFilters.department = 'all';
        } else if (payload.department && payload.department.includes("all")) {
            // remove other departments from the array and set it to 'all'
            payload.department = 'all';
            assignableRequestFilters.department = 'all';
        }
        switch (type) {
            case 'assignable':
                handleFilterChangeAssignable({ ...payload, disable: false });
                handleFilterChangeBasic({ ...payload, disable: false });
                if (
                    payload.staff !== 'all' ||
                    payload.department !== 'all' ||
                    payload.requestType === 'escalated' ||
                    payload.requestType === 'resolved' ||
                    caseWhenAssignableActive !== null
                ) {
                    handleFilterChangeBasic({ ...payload, disable: true, type: 'all', food: 'all' });
                } else {
                    handleFilterChangeBasic({ ...payload, disable: false, type: 'all', food: 'all' });
                }
                break;

            case 'basic':
                if(importedBy === 'Requests') break; // requests does not support basic requests, skip below code
                handleFilterChangeAssignable({ ...payload, disable: false });
                handleFilterChangeBasic({ ...payload, disable: false });
                if (payload.type !== 'all') {
                    handleFilterChangeAssignable({ ...payload, disable: true, staff: 'all', department: 'all' });
                } else {
                    handleFilterChangeAssignable({ ...payload, disable: false, staff: 'all', department: 'all' });
                }
                break;

            case 'resident':
                if (basicRequestFilters.disable === false) {
                    handleFilterChangeBasic({ ...basicRequestFilters, ...payload });
                }
                if (assignableRequestFilters.disable === false) {
                    handleFilterChangeAssignable({ ...assignableRequestFilters, ...payload });
                }
                break;

            default:
                setResident('all');
                const start = new Date();
                start.setDate(start.getDate() - 7); // Subtract 7 days from the current date
                const end = new Date();
                end.setDate(end.getDate() + 7); // Add 7 days to the current date
                setDates({ ...dates, fromDate: start, toDate: end });
                handleFilterChangeAssignable({
                    ...assignableRequestFilters,
                    disable: false,
                    staff: 'all',
                    department: 'all',
                    resident: 'all',
                    group: 'all',
                    fromDate: start,
                    toDate: end,
                });
                handleFilterChangeBasic({
                    ...basicRequestFilters,
                    disable: false,
                    type: 'all',
                    resident: 'all',
                    food: 'all',
                });
                setIsDateChangedManually(false);
        }
    };

    useEffect(() => {
        if (requests && requests.length) {
            const test: string[] = requests
                ? requests
                      .map((req) => (!('Department' in req) ? req['Type'] : ''))
                      .filter((val, index, arr) => arr.indexOf(val) === index) // to remove duplication in array
                      .filter((x) => x)
                : [];
            test && test.length ? setBasicRequestOptionsList(['all', ...test]) : setBasicRequestOptionsList(['all']);
        }
    }, [requests]);

    const requestsRefreshHandler = () => {
        setOpenReqCreateModal(false);
        handleFilterChangeAssignable({ ...assignableRequestFilters, reload: assignableRequestFilters.reload + 1 });
        handleFilterChangeBasic({ ...basicRequestFilters, reload: basicRequestFilters.reload + 1 });
    };

    const formatDateRange = (dateRange: { fromDate: Date; toDate: Date }) => {
        const startDate = dateRange.fromDate ? moment(dateRange.fromDate).format('MM/DD/YYYY') : 'none';
        const endDate = dateRange.toDate ? moment(dateRange.toDate).format('MM/DD/YYYY') : 'none';
        if (!dateRange.toDate || startDate === endDate) return startDate;
        return `${startDate} - ${endDate}`;
    };

    const closeAllRequestsYesClickHanlder = () => {
        const openRequests = [...(requests || [])]
            .filter((request) => request.Status && request.Status !== 'Closed')
        const deliveryRequestIds: string[] = [];
        const otherRequestIds: string[] = [];
        [...(openRequests || [])].forEach(order => {
            if (order.MealRequestIds && order.MealRequestIds.length > 0 && order.RequestType === 'Meal Delivery') {
                deliveryRequestIds.push(order._id);
            } else {
                otherRequestIds.push(order._id);
            }
        });

        if (otherRequestIds || deliveryRequestIds) {
            closeAll(otherRequestIds, deliveryRequestIds);
        }
    };

    const toggleConfirmationModal = () => {
        setShowConfirmationModal((prev) => !prev);
    };

    const sortedDepartments = sortByKey(departmentsOptionsList.map((item) => {
        return { value: item._id, text: item.Name };
    }));


    const toggleFavorite = async (id: string) => {
        const isRemoving = favorites.includes(id); // Check if the department is already a favorite
        try {
            const userId = profile && String(profile._id);
        if (!userId) return;
    
        setLoadingDepartments(id); // Start loading spinner for this department
    
        setFavorites((prevFavorites) =>
            isRemoving
                ? prevFavorites.filter((fav) => fav !== id) // Remove from local state
                : [...prevFavorites, id] // Add to local state
        );
    
        if (isRemoving) {
            // Call the remove endpoint
            const response = await removeUserFavoriteDepartment(userId, String(id));
            console.log("Removed favorite department: ", response);
            sendToast("success", "Department removed from favorites");
        } else {
            // Call the add endpoint
            const response = await addUserFavoriteDepartment(userId, String(id));
            console.log("Added favorite department: ", response);
            sendToast("success", "Department added to favorites");
        }   
        } catch (error) {
            console.log("Something went wrong while adding/removing favorite department: ", error);
            sendToast("error", `Failed to ${isRemoving ? 'remove' : 'add'} favorite department`);
        } finally {
            setLoadingDepartments(null); // Stop loading spinner for this department
        }        
    };
    const isClearFilterDisabled = assignableRequestFilters.department === 'all' && assignableRequestFilters.staff === 'all' && assignableRequestFilters.resident === 'all' && assignableRequestFilters.group === 'all' && isDateChangedManually === false;

    return (
        <div className="Reports" style={{ position: "relative" }}>
           
            <div >
                <Dimmer active={isFetching} inverted>
                    <Loader active={isFetching} />
                </Dimmer>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        marginLeft: importedBy === 'Requests' ? '10px' : '0px',
                        width: "100%",
                        flexWrap: 'wrap',
                    }}
                >
                    {/* <Dropdown
                        style={{ width: '140px', minWidth: '100px', marginRight: '5px' }}
                        placeholder='Request Type'
                        value={basicRequestFilters.type === 'all' ? '' : basicRequestFilters.type}
                        selection
                        scrolling
                        search
                        closeOnEscape
                        options={
                            basicRequestOptionsList.map(val => {
                                // capitalize first letter of the label
                                return { value: String(val), text: val && val[0].toUpperCase() + String(val).slice(1) || "" };
                            })
                        }
                        onChange={(event, data) => {
                            if (data.value === 'Menu') {
                                handleFilterChange("basic", { ...basicRequestFilters, type: data.value }, null);
                            } else {
                                handleFilterChange("basic", { ...basicRequestFilters, food: 'all', type: data.value }, null);
                            }
                        }}
                    /> */}
                    {importedBy === 'Requests' && (
                        <>
                            <Modal open={isModalOpen} size="small">
                                <Modal.Header>Closing requests</Modal.Header>
                                <Modal.Content>
                                    <div>{`${deletedRequests} of ${totalRequestsTobeDeleted} closed`}</div>
                                    <Progress color="teal" total={totalRequestsTobeDeleted} value={deletedRequests}></Progress>
                                </Modal.Content>
                            </Modal>
                            <div className="report-panel-row" style={{ alignItems: 'center', marginTop: '14px' }}>
                                <div style={{ width: "200px" }}>
                                    <div ref={calendarRef}>
                                        <Button
                                            onClick={() => setShowDatePicker((prev) => !prev)}
                                            className="date-range-selector"
                                        >
                                            <Icon name="calendar alternate outline" size="large" />
                                            <span style={{ fontSize: '12px' }}>{formatDateRange(dates)}</span>
                                        </Button>

                                        {showDatePicker && (
                                            <div style={{ position: 'absolute', zIndex: 2 }}>
                                                <DatePicker
                                                    selected={dates.fromDate}
                                                    startDate={dates.fromDate}
                                                    endDate={dates.toDate}
                                                    maxDate={importedBy !== "Requests" ? new Date() : null}
                                                    onChange={(startEndDates) => {
                                                        const [start, end] = startEndDates;
                                                        setDates({ ...dates, fromDate: start, toDate: end });
                                                        if (start && end) {
                                                            handleFilterChange(
                                                                'assignable',
                                                                { ...assignableRequestFilters, fromDate: start, toDate: end },
                                                                null,
                                                            );
                                                            handleFilterChange(
                                                                'basic',
                                                                { ...basicRequestFilters, fromDate: start, toDate: end },
                                                                null,
                                                            );
                                                            setShowDatePicker(false);
                                                            setIsDateChangedManually(true);
                                                        }
                                                    }}
                                                    selectsRange
                                                    inline
                                                    style={{ position: 'absolute', top: 0, left: 0 }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    <div className="filterContainer">
                        <p style={{ fontSize: '12px', marginBottom: '0px' }}>Staff</p>
                        <Dropdown
                            style={{
                                width: importedBy === 'Requests' ? '200px' : '300px',
                                minWidth: '100px',
                                marginRight: '5px',
                            }}
                            placeholder="Staff"
                            value={assignableRequestFilters.staff}
                            selection
                            search
                            scrolling
                            options={[
                                { value: 'all', text: 'All' },
                                ...sortByKey(staffOptionsList.map((item) => {
                                    return { value: item._id, text: `${item.FirstName} ${item.LastName}` };
                                })),
                            ]}
                            onChange={(event, data) => {
                                console.log('assignableRequestFilters.staff: ', typeof assignableRequestFilters.staff);
                                handleFilterChange(
                                    'assignable',
                                    { ...assignableRequestFilters, staff: data.value },
                                    null,
                                );
                            }}
                        />
                    </div>
                    <div className="filterContainer">
                        <p style={{ fontSize: '12px', marginBottom: '0px' }}>Resident</p>
                        <Dropdown
                            style={{
                                width: importedBy === 'Requests' ? '200px' : '300px',
                                minWidth: '100px',
                                marginRight: '5px',
                            }}
                            placeholder="Resident"
                            value={resident}
                            selection
                            scrolling
                            search
                            options={[
                                { value: 'all', text: 'All' },
                                ...sortByKey(residentsOptionsList.map((item) => {
                                    return { value: item._id, text: `${item.FirstName} ${item.LastName}` };
                                })),
                            ]}
                            onChange={(event, data) => {
                                console.log('resident :', resident);
                                //@ts-ignore
                                setResident(data.value);
                                handleFilterChange('resident', { resident: data.value }, null);
                            }}
                        />
                    </div>
                    <div className="filterContainer">
                        <p style={{ fontSize: '12px', marginBottom: '0px' }}>Groups</p>
                        <Dropdown
                            style={{
                                width: importedBy === 'Requests' ? '200px' : '300px', // todo figure out where and all is this thing shown and see if we need to show there and then update the code accordingly
                                minWidth: '100px',
                                marginRight: '5px',
                            }}
                            placeholder="Groups"
                            value={assignableRequestFilters.group}
                            selection
                            scrolling
                            search
                            options={[
                                { value: 'all', text: 'All' },
                                ...sortByKey(groupsOptionsList.map((item) => {
                                    return { value: item._id, text: item.Name };
                                })),
                            ]}
                            onChange={(event, data) => {
                                handleFilterChange(
                                    'assignable',
                                    { ...assignableRequestFilters, group: data.value },
                                    null,
                                );
                            }}
                        />
                    </div>

                    <div className="filterContainer">
                        <p style={{ fontSize: '12px', marginBottom: '0px' }}>Department</p>
                        <Dropdown
                            style={{
                                width: importedBy === 'Requests' ? '100%' : '300px',
                                minWidth: '250px',
                                marginRight: '5px',
                                display: 'flex',
                                gap: '5px',
                                maxWidth: '460px',
                                flexWrap: 'wrap',
                            }}
                            placeholder="Department"
                            value={assignableRequestFilters.department || []}
                            selection
                            fluid
                            scrolling
                            multiple
                            search
                            loading={isDepartmentLoading}
                            defaultValue={assignableRequestFilters.department}
                            options={[
                                { value: 'all', text: 'All' },
                                ...sortedDepartments.map((item) => {
                                    const isFavorite = favorites.includes(item.value); // Check if department is a favorite
                                    const isLoading = loadingDepartments === item.value; // Check if this department is loading
                                    return {
                                        value: item.value,
                                        content: (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <span>{item.text}</span>
                                                {isLoading ? (
                                                    <Icon
                                                        name="spinner"
                                                        loading
                                                        style={{
                                                            marginLeft: '10px',
                                                        }}
                                                    />
                                                ) : (
                                                    <span
                                                        style={{
                                                            marginLeft: '10px',
                                                            cursor: 'pointer',
                                                            color: isFavorite ? 'Red' : 'Black',
                                                        }}
                                                        onClick={async (e) => {
                                                            e.stopPropagation(); // Prevent dropdown selection
                                                            await toggleFavorite(item.value);
                                                        }}
                                                    >
                                                        <Icon name={isFavorite ? 'star' : 'star outline'} />
                                                    </span>
                                                )}
                                            </div>
                                        ),
                                        text: item.text,
                                    };
                                }),
                            ]}
                            onChange={(event, data) => {
                                handleFilterChange(
                                    'assignable',
                                    { ...assignableRequestFilters, department: data.value },
                                    null,
                                );
                            }}
                            renderLabel={(label) => ({
                                content: label.content,
                                style: {
                                    display: 'flex',
                                    padding: '6px',
                                    margin: '2px',
                                    borderRadius: '5px',
                                    width: 'fit-content',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                },
                            })}
                        />
                    </div>

                    {basicRequestFilters.type === 'Menu' ? (
                        <Dropdown
                            style={{ width: '140px', minWidth: '100px', marginRight: '5px' }}
                            placeholder="Food"
                            value={basicRequestFilters.food === 'all' ? '' : basicRequestFilters.food}
                            selection
                            scrolling
                            options={[
                                { value: 'all', text: 'All' },
                                { value: 'breakfast', text: 'Breakfast' },
                                { value: 'lunch', text: 'Lunch' },
                                { value: 'snacks', text: 'Snacks' },
                                { value: 'dinner', text: 'Dinner' },
                            ].sort((a, b) => a.text.localeCompare(b.text))}
                            onChange={(event, data) => {
                                handleFilterChange('basic', { ...basicRequestFilters, food: data.value }, null);
                            }}
                        />
                    ) : (
                        <></>
                    )}
                    <div className="filterContainer">
                        <Button
                            style={{ width: '140px', marginTop: '15px', marginLeft: "5px" }}
                            disabled={isClearFilterDisabled}
                            onClick={() => {
                                handleFilterChange('default', {});
                            }}
                        >
                            Clear Filters
                        </Button>
                    </div>
                    {
                        importedBy === 'Requests' && (
                            <div className="filterContainer mt-10 ml-10">
                                {shouldCloseAllBeShown && (
                                    <Popup
                                        content="Close in view"
                                        trigger={
                                            <div
                                                className="close-all-button"
                                                onClick={() => {
                                                    if (blockCloseInView) {
                                                        toast.warn(
                                                            'Please make sure list view is open for all the columns to access close in view feature',
                                                            {
                                                                position: 'bottom-center',
                                                                autoClose: 5000,
                                                                hideProgressBar: false,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                            },
                                                        );
                                                        return;
                                                    }
                                                    toggleConfirmationModal();
                                                }}
                                            >
                                                <div style={{ marginTop: "2px", marginLeft: "4px" }}>
                                                    <Icon name="close" />
                                                    {/* <div className="text">Close All Presented</div> */}
                                                </div>
                                            </div>
                                        }
                                    ></Popup>
                                )}
                            </div>
                        )
                    }
                    <div className="filterContainer mt-10 ml-10">
                        {shouldCreateRequestBeShown && (
                            <Button
                                style={{ backgroundColor: 'white', color: '#1991EB', border: '1px solid #1991EB' }}
                                onClick={() => {
                                    createReqBtnCilckHandler();
                                }}
                            >
                                <Icon name="add" className="button-icon" style={{ color: '#1991EB' }} />
                                Create Request
                            </Button>
                        )}
                    </div>
                </div>
            </div>
            {importedBy === 'Requests' ? (
                <>
                    {openReqCreateModal && (
                        <Modal
                            open={openReqCreateModal}
                            onClose={() => setOpenReqCreateModal(false)}
                            onOpen={() => setOpenReqCreateModal(true)}
                            style={{ width: '450px' }}
                        >
                            <Modal.Content>
                                <CreateRequestModal
                                    setOpen={setOpenReqCreateModal}
                                    facilityId={(profile && profile.Facility) || ''}
                                    refresh={requestsRefreshHandler}
                                />
                            </Modal.Content>
                        </Modal>
                    )}
                    <Modal open={showConfirmationModal} closeIcon onClose={toggleConfirmationModal}>
                        <Modal.Header>
                            Are you sure you want to close all displayed requests? This cannot be undone.
                        </Modal.Header>
                        <Modal.Actions>
                            <Button color="red" basic onClick={closeAllRequestsYesClickHanlder}>
                                Yes
                            </Button>
                            <Button color="grey" basic onClick={toggleConfirmationModal}>
                                Cancel
                            </Button>
                        </Modal.Actions>
                    </Modal>
                </>
            ) : (
                ''
            )}
        </div>
    );
};

export default ReportSummaryAll;
