import React from 'react';
import { Modal } from 'semantic-ui-react';
import SingleRequest from '../../pages/StaffApp/Requests/SingleRequest';
import AdminSingleRequest from '../../pages/AdminPanel/Requests/Single';
import { Request } from '../../types';

type Props = {
    requestDetailedModal: boolean;
    setRequestDetailedModal: (value: boolean) => void;
    request: Request;
    refresh: () => void;
    powerAndFacilityUsers?: boolean;
};

const RequestInfoModal = ({
    requestDetailedModal,
    setRequestDetailedModal,
    request,
    refresh,
    powerAndFacilityUsers
}: Props) => {
    return (
        <Modal
            open={requestDetailedModal}
            onClose={() => setRequestDetailedModal(false)}
            size="tiny"
            closeIcon={true}
        >
            <Modal.Header>Request Info</Modal.Header>
            <Modal.Content>
                {request ? <Modal.Description>
                    {
                        powerAndFacilityUsers ?
                            <AdminSingleRequest
                                refresh={refresh}
                                similarRequestIds={request.similarRequestIds}
                                requestId={request._id}
                            /> :
                            <SingleRequest
                                requestId={request._id}
                                refresh={refresh}
                                similarRequestIds={request.similarRequestIds}
                            />
                    }
                </Modal.Description> : <></>}
            </Modal.Content>
        </Modal>
    );
};

export default RequestInfoModal;