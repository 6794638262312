import React, { FC, useEffect, useRef, useState } from 'react';
import { Button, Dropdown, DropdownProps, Grid, Loader, Modal, Popup, Image, Header, Input, Dimmer, } from 'semantic-ui-react';
import { fetchAllPaginatedResidents, fetchUnitIdFromResidentId, updateRegistrant } from '../../services/Registrants';
import { AuthState, Registrant } from '../../types';
import { sendToast } from '../../util';
import UserItem from '../UserItem';
import Webcam from 'react-webcam';
import useWebSocket from '../../hooks/websocket';
import { useSelector } from 'react-redux';
import { AppState } from '../../reducers';
import ResidentModalCalendarComponent from './ResidentModalCalendarComponent';
import { toast } from 'react-toastify';
import { sortByKey } from '../../util/sortData';
import CallList from './CallList';
import './style.less';
import { getSmartGadget } from '../../services/SmartGadgets';

interface Props {
    fullScreen: boolean;
    onModalClose: () => void;
    importedByRequests?: boolean;
    residentId?: string;
    modalLoading?: boolean;
    setModalLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}
const ResidentsLiveCall: FC<Props> = ({ fullScreen, onModalClose, importedByRequests = false, residentId, modalLoading, setModalLoading }) => {
    const profile = useSelector(({ authReducer }: { authReducer: AuthState; }) => {
        return authReducer.profile;
    });
    const profileId = profile ? profile._id : "";
    const [residents, setResidents] = useState<Registrant[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedResident, setSelectedResident] = useState<Registrant | null>();
    const localStream = useRef<MediaStream | null>(null);
    const remoteVideo = useSelector(({ remoteVideoReducer }: AppState) => {
        return remoteVideoReducer.remoteVideo;
    });
    const [isCallIncoming, isOutboundCallInitiated, isCallAccepted, isCallInProgress, isReconnecting, startCall, endCall, handleIncomingCallAccecpt, caller, isCallRinging, sessionId] = useWebSocket("wss://calling.speak2.live/ws", profileId, () => localStream.current, setRegistrantToNull);
    const [activeCamera, setActiveCamera] = useState('');
    const [availableDevices, setAvailableDevices] = useState<any>([]);
    const [initialCamera, setInitialCamera] = useState<string>(''); // State variable for the initial camera device ID
    const webcamRef = useRef(null);
    const [notesUpdating, setNotesUpdating] = useState(false);
    const [isResidentHaveAlexaAndComprofile, setIsResidentHaveAlexaAndComprofile] = useState(false);
    const [isFirstCall, setIsFirstCall] = useState<boolean>(true);

    useEffect(() => {
        async function getCameraDevices() {
            try {
                const devices = await navigator.mediaDevices.enumerateDevices();
                const cameras = devices.filter(device => device.kind === 'videoinput');
                console.log({ cameras });
                setInitialCamera(cameras.length > 0 ? cameras[0].deviceId : '');
                setAvailableDevices(cameras);
            } catch (error) {
                sendToast("error", error instanceof Error ? error.message : "Failed to fetch camera devices");
            }
        }
        getCameraDevices();
    }, []);

    function videoRefCallback(videoElement: HTMLVideoElement) {
        if (videoElement && remoteVideo) {
            videoElement.srcObject = remoteVideo;
        }
    }

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                const residents = await fetchAllPaginatedResidents();
                if (importedByRequests && residentId) {
                    if (residentId && !isOutboundCallInitiated && !isCallInProgress && !isCallIncoming) {
                        const selectedResident = residents.filter(resident => String(resident._id) === String(residentId))[0];
                        const isResidentHaveAlexa = await isResidentHaveAlexaOrComprofile(selectedResident);
                        if (isResidentHaveAlexa) {
                            setSelectedResident(selectedResident);
                        }
                    }
                }
                setResidents(residents);
            } catch (error) {
                console.error("ResidentsLiveCall", error);
                const errorMessage = error instanceof Error ? error.message : "Failed to fetch residents";
                sendToast("error", errorMessage);
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);

    useEffect(() => {
        if (caller && caller.callerId) {
            const selectedResident = residents.filter(resident => resident._id === caller.callerId)[0];
            setSelectedResident(selectedResident);
        }
    }, [caller]);

    useEffect(() => {
        if (importedByRequests && caller && initialCamera && selectedResident && availableDevices && availableDevices.length > 0 && isFirstCall) {
            onCameraClick();
            setIsFirstCall(false);
        }
    }, [selectedResident, initialCamera]);

    const residentsDropdownOptions = residents.map(resident => {
        return {
            key: resident._id,
            value: resident._id,
            text: `${resident.FirstName} ${resident.LastName}`
        };
    });

    const deviceOptions = availableDevices.map(device => {
        const labelParts = device.label.split('(');
        const labelText = labelParts.length > 0 ? labelParts[0].trim() : `Camera ${device.deviceId}`;
        return {
            key: device.deviceId,
            value: device.deviceId,
            text: labelText,
        };
    });

    const handleCameraChange = (data) => {
        const { value } = data;
        if (value) {
            setActiveCamera(value);
        }
    };

    const onDropdownChange = async (_, data: DropdownProps) => {
        const { value } = data;
        console.log({ value });
        if (value && !isOutboundCallInitiated && !isCallInProgress && !isCallIncoming) {
            const selectedResident = residents.filter(resident => resident._id === value)[0];
            const isResidentHaveAlexa = await isResidentHaveAlexaOrComprofile(selectedResident);
            if (isResidentHaveAlexa) {
                setSelectedResident(selectedResident);
            }      
        } else {
            toast.error("Cannot switch resident during call", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    };

    const isResidentHaveAlexaOrComprofile = async (resident: Registrant) => {
        try {
            const { Unit } = resident;
            if (Unit && Unit.a4hCommProfileId && Unit.a4hRoomId) {
                setIsLoading(true);
                const gadgets = await getSmartGadget(Unit.a4hRoomId);
                if (gadgets) {
                    const alexaDevices = gadgets.filter(gadget => gadget.isAlexaDevice);
                    if (!alexaDevices.length) {
                        sendToast("error", "Resident does not have any alexa devices");
                    } else {
                        setIsResidentHaveAlexaAndComprofile(true);
                        return true;
                    }
                } else {
                    sendToast("error", "Resident does not have any alexa devices");
                }
            } else {
                sendToast("error", "Resident does not have a4hCommProfileId or a4hRoomId to place a call");
            }
        } catch (error) {
            sendToast("error", "Failed to Retrieve Alexa devices for the resident");
        } finally {
            setIsLoading(false);
        }
    }

    const onPremiseChangeHandler = (onPremise: boolean) => {
        setSelectedResident((prev) => {
            if (prev) {
                return {
                    ...prev,
                    onPremise
                };
            }
            // Handle the case where prev is null.
            return null;
        });
    };

    const onSocialOptOutChangeHandler = (socialOptOut: boolean) => {
        setSelectedResident((prev) => {
            if (prev) {
                return {
                    ...prev,
                    Private: socialOptOut
                };
            }
            // Handle the case where prev is null.
            return null;
        });
    };

    const disableCameraIcon = () => {
        return !selectedResident || (!selectedResident.Unit || selectedResident.onPremise === false) || isReconnecting || !isResidentHaveAlexaAndComprofile;
    };

    const onCameraClick = async () => {
        try {
            if (selectedResident) {
                const { unitId } = selectedResident && await fetchUnitIdFromResidentId({ residentId: selectedResident._id });
                if (!unitId) {
                    toast.error("Room not found for the selected resident", {
                        position: 'bottom-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    return;
                }
                await startCall(String(unitId));
            } else {
                toast.warn("Please select a resident to initiate the call.", {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
        } catch (error) {
            console.error("ResidentsLiveCall", error);
            const errorMessage = error instanceof Error ? error.message : "Failed to initiate call";
            sendToast("error", errorMessage);
        }
    };


    const CameraIcon = () => {
        if (isOutboundCallInitiated) {
            return fullScreen ? <>
                <Loader active inline size={'small'} className='modalLoader' />
                <span style={{ color: 'black', marginLeft: '5px' }}>Call Ringing...</span>  {/* the loader component is displaying white text , the loader text is not visible in the modal this is the reason we using span */}
            </> : <></>;
        }
        if (!isCallInProgress && !isOutboundCallInitiated && !isCallIncoming) {
            const isDisabled = disableCameraIcon();
            const cameraIcon = (
                <Image src={`${process.env.PUBLIC_URL}/icons8-circled-play-30.png`} alt="Notification Icon"
                    style={{ display: "block", cursor: "pointer", ...fullScreen ? { width: '40px', height: 'auto' } : {} }}
                    disabled={isDisabled}
                    onClick={onCameraClick}
                />
            );

            return isDisabled ? (
                <Popup
                    content="Please ensure that the resident is selected, on premise and associated with a room to initiate the call"
                    trigger={cameraIcon}
                />
            ) : (
                cameraIcon
            );
        } else {
            return <></>;
        }
    };

    const ResidentDropDown = () => {
        const dropDown = (
            <Dropdown
                options={sortByKey(residentsDropdownOptions)}
                placeholder="Resident"
                selection
                loading={isLoading}
                onChange={onDropdownChange}
                search
                value={selectedResident ? selectedResident._id : ""}
            />
        );

        return (
            <div style={{
                display: 'flex',
            }}>
                {dropDown}
            </div>

        );
    };

    function setRegistrantToNull() {
        setSelectedResident(null);
    }

    const RejectCallButton = () => {
        return (
            (isCallIncoming) ? <Button
                color='red'
                onClick={() => {
                    setSelectedResident(null);
                    endCall(true);
                }}
                content={`Reject Call`}
                icon='call'
                labelPosition='left'
                size={fullScreen ? "small" : "medium"}
            /> : <></>
        );
    };

    const endCallIcon = <svg onClick={() => {
        setSelectedResident(null);
        endCall(true);
    }} style={{ ...fullScreen ? { width: '35px', height: 'auto', margin: "0px 10px" } : { width: '29px', padding: '2px' }, cursor: "pointer" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#ff0000" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" /></svg>;

    const EndCallButton = () => {
        return (
            (isCallInProgress || isOutboundCallInitiated || selectedResident) ? endCallIcon : <></>
        );
    };

    const EndResidentCallIcon = () => {
        return (
            (isCallInProgress || isCallIncoming || isOutboundCallInitiated || selectedResident) ? endCallIcon : <></>
        );
    };

    const AcceptCallButton = () => {
        return (
            (isCallIncoming && !isCallAccepted) ? (
                fullScreen ? (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Header as='h4' style={{ marginRight: '10px', marginBottom: '0' }}>
                                {caller.callerName ? caller.callerName : "Unknown"}
                            </Header>
                            <Button
                                color='green'
                                onClick={handleIncomingCallAccecpt}
                                content='Accept Call'
                                icon='call'
                                labelPosition='left'
                                size={fullScreen ? "small" : "medium"}
                            />
                        </div>
                    </div>
                ) : (
                    <>
                        <Header as='h3' style={{ color: "white" }}>{caller.callerName ? caller.callerName : "Unknown"}</Header>
                        <Button
                            color='green'
                            onClick={handleIncomingCallAccecpt}
                            content='Accept Call'
                            icon='call'
                            labelPosition='left'
                            size={fullScreen ? "small" : "medium"}
                        />
                    </>
                )
            ) : null
        );
    };

    const handleCameraError = (error: DOMException | string) => {
        console.error("Webcam Error", error);
        let errorMessage = "";
        if (typeof error === "string") {
            errorMessage = error;
        } else {
            if (error.message.includes("Permission denied")) {
                errorMessage = "Please allow access to both camera and microphone to use the live call feature.";
            } else if (error.message.includes("NotAllowedError")) {
                errorMessage = "Please allow access to both camera and microphone to use the live call feature.";
            } else if (error.message.includes("NotFoundError")) {
                errorMessage = "Camera and microphone not found. Please ensure they are properly connected and try again.";
            } else {
                errorMessage = "An error occurred while accessing the camera and microphone. Please try again.";
            }
        }
        sendToast("error", errorMessage);
    };

    const videoConstraints = {
        width: 1280,
        height: 720,
        // facingMode: "user",
        deviceId: activeCamera
    };

    const renderResidentCall = (dontRender = false) => {
        return <Grid stretched columns={3} padded relaxed verticalAlign='top'>
            {
                !importedByRequests && !fullScreen &&
                <Grid.Column largeScreen={4} computer={3} verticalAlign="top" style={{ position: "relative", padding: "0px 10px" }}>
                    <div style={{ height: '379px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <div>
                            {!isCallInProgress && !isCallIncoming && !isOutboundCallInitiated && selectedResident && (
                                <div style={{ fontSize: '12px', display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                    <Dropdown
                                        options={sortByKey(deviceOptions)}
                                        placeholder="Select Camera"
                                        selection
                                        onChange={(_, data) => handleCameraChange(data)}
                                        value={activeCamera || initialCamera || ""}
                                    />
                                </div>
                            )}
                            <div style={{ fontSize: '12px' }}>
                                {
                                    isCallInProgress
                                        ? <></> : !isOutboundCallInitiated
                                            ? <>
                                            </>
                                            : !isCallRinging ? <Loader active inline='centered' size='medium' style={{ color: 'white' }}>
                                                Trying to reach the resident... Please wait.
                                            </Loader> : <Loader active inline='centered' size='medium' style={{ color: 'white' }}>
                                                Ringing....
                                            </Loader>
                                }
                            </div>
                            <div style={{ fontSize: '12px', display: 'flex', alignItems: 'center' }}>
                                <ResidentDropDown />
                            </div>
                            <div style={{ marginTop: '5px' }}>
                                {
                                    selectedResident ?
                                        <UserItem
                                            isFullScreen={fullScreen}
                                            id={selectedResident._id}
                                            name={`${selectedResident.FirstName} ${selectedResident.LastName}`}
                                            details={selectedResident.SpecialHandling || selectedResident.Notes || ''} onClick={() => { }}
                                            source="home"
                                            residentPremise={selectedResident.onPremise}
                                            roomName={(selectedResident.Unit && selectedResident.Unit.Name) || ''}
                                            isExternalResident={selectedResident.ExternalIntegrationSrc ? true : false}
                                            isAlisResident={selectedResident.alisResidentId ? true : false}
                                            externalIntegrationSource={selectedResident.ExternalIntegrationSrc}
                                            isWebRTC={true}
                                        /> :
                                        <></>
                                }
                            </div>
                            <AcceptCallButton />
                        </div>
                    </div>
                </Grid.Column>
            }
            { !importedByRequests ? <><Grid.Column largeScreen={fullScreen ? 16 : 9} computer={fullScreen ? 16 : 10} style={{ background: "none", position: "relative", padding: 0 }}>
                <div style={{ width: '100%', background: '#f0f0f0', borderRadius: '5px', height: '20vw' }}>
                    <div style={{
                        position: 'absolute',
                        top: '10px',
                        left: '10px',
                        zIndex: 100,
                        display: 'flex',
                    }}>
                        <div>
                            <CameraIcon />
                        </div>
                        <div>
                            <EndResidentCallIcon />
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            width: "100%",
                            paddingTop: "62%",
                            position: "relative"
                        }}
                    >
                        {
                            remoteVideo ?
                                <video
                                    ref={videoRefCallback}
                                    autoPlay
                                    playsInline
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                    }}
                                />
                                :
                                <></>
                        }
                        <div style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                        }}>

                        </div>

                    </div>
                    <div style={{
                        position: "absolute",
                        bottom: "5px",
                        right: "5px",
                        width: "120px",
                        height: "90px",
                        borderRadius: "5px",
                        zIndex: 100,
                        overflow: "hidden",
                    }}>
                        {(selectedResident || isCallIncoming || isCallInProgress) && <Webcam
                            ref={webcamRef}
                            audio={true}
                            onUserMediaError={handleCameraError}
                            videoConstraints={videoConstraints}
                            style={{ width: "100%", height: "100%", objectFit: "cover" }}
                            onUserMedia={(stream) => {
                                localStream.current = stream;
                            } } />}
                    </div>
                </div>
            </Grid.Column><Grid.Column width={3} style={{ padding: 0, }}>
                    <CallList staffId={profileId} showMissed={true} setSelectedResident={(selectedResidentId: string) => updateSelectedResidentFromCallList(selectedResidentId)} />
                </Grid.Column></>: <></>}
            {(!dontRender && fullScreen) && ResidentModal()}
        </Grid>;
    };

    const updateNotes = async (selectedResident: Registrant) => {
        try {
            setNotesUpdating(true);
            await updateRegistrant(String(selectedResident._id), { Notes: selectedResident.Notes, _id: String(selectedResident._id) });
            // update the notes for resident in the list
            const updatedResidents = residents.map(resident => {
                if (String(resident._id) === String(selectedResident._id)) {
                    return {
                        ...resident,
                        Notes: selectedResident.Notes
                    };
                }
                return resident;
            });
            setResidents(updatedResidents);
        } catch {
            toast.error("Failed to update notes", {
                position: 'bottom-center',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } finally {
            setNotesUpdating(false);
        }
    };

    const notesOnChange = (e, { value }) => {
        setSelectedResident((prev) => {
            if (prev) {
                return {
                    ...prev,
                    Notes: value
                };
            }
            return null;
        });
    };

    const updateSelectedResidentFromCallList = (selectedResidentId: string) => {
        if (selectedResidentId) {
            const selectedResident = residents.find(resident => String(resident._id) === selectedResidentId);
            setSelectedResident(selectedResident);
        }
    };

    const ResidentModal = () => {
        return (
            <Modal size='fullscreen' closeIcon onClose={onModalClose} open={fullScreen} >
                <Modal.Header>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ fontSize: '12px' }}>
                            <ResidentDropDown />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px', fontSize: '14px' }}>
                            <CameraIcon />
                            <EndCallButton />
                        </div>
                        <div style={{ marginLeft: '10px', fontSize: '14px', display: 'flex', alignItems: 'center' }}>
                            <AcceptCallButton />
                            <RejectCallButton />
                        </div>
                        <div style={{ background: '#f0f0f094', display: 'flex', alignItems: 'center', fontSize: '14px', marginLeft: '14px', borderRadius: '4px', padding: "5px" }}>
                            <div>
                                {selectedResident && (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span style={{ paddingLeft: '9px' }}>Room {(selectedResident.Unit && selectedResident.Unit.Name) || ''}</span>
                                    </div>
                                )}
                            </div>
                            <div style={{ marginLeft: '12px' }}>
                                {selectedResident && (
                                    <UserItem
                                        isFullScreen={fullScreen}
                                        id={selectedResident._id}
                                        name={`${selectedResident.FirstName} ${selectedResident.LastName}`}
                                        details={selectedResident.SpecialHandling || selectedResident.Notes || ''}
                                        onClick={() => { }}
                                        source="home"
                                        onPremiseChangeHandler={onPremiseChangeHandler}
                                        residentPremise={selectedResident.onPremise}
                                        onSocialOptOutChangeHandler={onSocialOptOutChangeHandler}
                                        residentSocialOptOut={selectedResident.Private || false}
                                        roomName={(selectedResident.Unit && selectedResident.Unit.Name) || ''}
                                        isExternalResident={selectedResident.ExternalIntegrationSrc ? true : false}
                                        isAlisResident={selectedResident.alisResidentId ? true : false}
                                        externalIntegrationSource={selectedResident.ExternalIntegrationSrc}
                                    />
                                )}
                            </div>
                        </div>
                        <div>
                            {selectedResident &&
                                <Input
                                    disabled={notesUpdating}
                                    placeholder='Resident notes'
                                    size='mini'
                                    value={selectedResident.Notes || ''}
                                    style={{ marginLeft: '10px', width: '100%', fontSize: '12px' }}
                                    action={{
                                        type: 'button',
                                        content: 'Update',
                                        onClick: async () => await updateNotes(selectedResident),
                                    }}
                                    onChange={(e, { value }) => notesOnChange(e, { value })}
                                />
                            }
                        </div>
                    </div>

                </Modal.Header>
                <Modal.Content>
                    {
                        isCallInProgress
                            ? <></>
                            : !selectedResident
                                ? <p className='headerFontSize'>Please select a resident to initiate the call.</p>
                                : !isOutboundCallInitiated && !isCallIncoming
                                    ? <p className='headerFontSize'>Waiting to call the resident...</p>
                                    : <></>
                    }
                    <ResidentModalCalendarComponent
                        registrant={selectedResident ? selectedResident : null} key={selectedResident ? selectedResident._id : ""}
                        videoRefCallback={videoRefCallback} isCallInProgress={isCallInProgress}
                        isOutboundCallInitiated={isOutboundCallInitiated}
                        localStream={localStream}
                        isCallIncoming={isCallIncoming}
                        handleCameraError={handleCameraError}
                        videoConstraints={videoConstraints}
                        remoteVideo={remoteVideo}
                        sessionId={sessionId}
                    />
                </Modal.Content>
            </Modal>
        );
    };

    return (
        renderResidentCall()
    );
};

export default ResidentsLiveCall;